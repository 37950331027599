import { gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import SegmentGenerated, { SegmentGeneratedEntityManager } from './generated/Segment.generated';
import SegmentCondition from './SegmentCondition';

export class SegmentEntityManager extends SegmentGeneratedEntityManager<Segment>
{
    constructor()
    {
        super({
            createEntity: () => new Segment(),
        });
    }
}

export class Segment extends SegmentGenerated
{
    static manager: SegmentEntityManager = new SegmentEntityManager();

    static async createSegment(name: string, description: string | null = null, conditions: SegmentCondition[] = [])
    {
        let query = `
            mutation CreateSegment($name: String!, $description: String, $conditions: [SegmentConditionSimpleInput]) {
                createSegment(name: $name, description: $description, conditions: $conditions) {
                    object {
                        ...${Segment.manager.name}DetailsFragment
                    }
                    success
                }
            }
            ${Segment.manager.buildEntityDetailsFragment()}
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                name: name,
                description: description,
                conditions: conditions.map(condition => ({
                    fieldName: condition.fieldName,
                    operator: condition.operator,
                    value: condition.value,
                }))
            }
        });

        if (result.data.createSegment.success)
        {
            return Segment.manager.createFromGraphQL(result.data.createSegment.object);
        }
        else
        {
            return null;
        }
    }

    static async updateSegmentConditions(segment: Segment, conditions: SegmentCondition[] = [])
    {
        let query = `
            mutation UpdateSegmentConditions($segmentId: Int!, $conditions: [SegmentConditionSimpleInput]) {
                updateSegmentConditions(segmentId: $segmentId, conditions: $conditions) {
                    object {
                        ...${Segment.manager.name}DetailsFragment
                    }
                    success
                }
            }
            ${Segment.manager.buildEntityDetailsFragment()}
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                segmentId: segment.id,
                conditions: conditions.map(condition => ({
                    fieldName: condition.fieldName,
                    operator: condition.operator,
                    value: condition.value,
                }))
            }
        });

        if (result.data.updateSegmentConditions.success)
        {
            return Segment.manager.createFromGraphQL(result.data.updateSegmentConditions.object);
        }
        else
        {
            return null;
        }
    }
}

export default Segment;
