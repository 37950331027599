import React from 'react';
import { useState } from 'react';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import BusinessEntity from '@xFrame4/business/base/BusinessEntity';
import { useSetStateFromProp } from '@xFrame4/components/Hooks';

interface EntityGridProps<B extends BusinessEntity>
{
    entities: B[];
    columns: GridColumns<B>;
    isLoading?: boolean;
    currentPage?: number; 
    pageSize?: number;
    rowCount?: number;
    onRowDoubleClick?: (row: B) => void;
    onPageChange?: (oldPage: number, newPage: number) => void;
}

function EntityGrid<B extends BusinessEntity>(props: EntityGridProps<B>)
{
    const [currentPage, setCurrentPage] = useState<number>();
    useSetStateFromProp(setCurrentPage, props.currentPage);

    /** On page change (forward/backward) */
    const onPageChange = (newPage: number) =>
    {
        if (currentPage !== undefined)
        {
            let oldPage = currentPage;
            if (props.onPageChange && oldPage != newPage) props.onPageChange(oldPage, newPage);
            setCurrentPage(newPage);
        }
    };

    /** Render. */
    return (
        <div className="w-100">
            <DataGrid
                sx={{
                    color: 'var(--ct-input-color) !important',
                    border: 'none',
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiTablePagination-displayedRows': {
                        color: 'var(--ct-input-color) !important',
                        marginBottom: '0px',
                    }
                }}
                autoHeight
                disableColumnFilter
                disableColumnMenu
                columns={props.columns}
                rows={props.entities}
                paginationMode="server"
                pageSize={props.pageSize ?? 50}
                rowsPerPageOptions={[props.pageSize ?? 50]}
                rowCount={props.rowCount ?? 0}
                page={currentPage ?? 0}
                loading={props.isLoading}
                onRowDoubleClick={(params) => { if (props.onRowDoubleClick) props.onRowDoubleClick(params.row) }}
                onPageChange={(newPage) => onPageChange(newPage)}
            />
        </div>
    );
}


export default EntityGrid;