import CustomFieldGenerated, { CustomFieldGeneratedEntityManager } from './generated/CustomField.generated';

export type CustomFieldValue = {
    customField: CustomField,
    value: any
}

export class CustomFieldEntityManager extends CustomFieldGeneratedEntityManager<CustomField>
{
    constructor()
    {
        super({
            createEntity: () => new CustomField(),
        });
    }
}

export class CustomField extends CustomFieldGenerated
{
    static manager: CustomFieldEntityManager = new CustomFieldEntityManager();
}

export default CustomField;