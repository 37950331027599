import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import ContactGroup from '@business/contacts/ContactGroup';

export enum OptInFormTheme
{
    DEFAULT = 'DEFAULT',

}

export class OptInFormGeneratedEntityManager<B extends OptInFormGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'OptInForm',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'description', type: BusinessEntityFieldType.Text },
				{ name: 'markAsConfirmed', type: BusinessEntityFieldType.Boolean },
				{ name: 'theme', type: BusinessEntityFieldType.VarChar },
				{ name: 'isPopup', type: BusinessEntityFieldType.Boolean },
				{ name: 'popupDelay', type: BusinessEntityFieldType.Integer },
				{ name: 'headerTitle', type: BusinessEntityFieldType.VarChar },
				{ name: 'headerSubtitle', type: BusinessEntityFieldType.VarChar },
				{ name: 'bodyText', type: BusinessEntityFieldType.Text },
				{ name: 'submitButtonCta', type: BusinessEntityFieldType.VarChar },
				{ name: 'submitButtonText', type: BusinessEntityFieldType.VarChar },
				{ name: 'declineText', type: BusinessEntityFieldType.VarChar },
				{ name: 'footerText', type: BusinessEntityFieldType.Text },
				{ name: 'thankYouTitle', type: BusinessEntityFieldType.VarChar },
				{ name: 'thankYouText', type: BusinessEntityFieldType.Text },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'optInForms',
        });
    }

}
export class OptInFormGenerated extends BusinessEntity
{
	name!: string;
	description: string = '';
	markAsConfirmed: boolean = false;
	theme: OptInFormTheme = OptInFormTheme.DEFAULT;
	isPopup: boolean = false;
	popupDelay: number = 0;
	headerTitle: string = '';
	headerSubtitle: string = '';
	bodyText: string = '';
	submitButtonCta: string = '';
	submitButtonText: string = '';
	declineText: string = '';
	footerText: string = '';
	thankYouTitle: string = '';
	thankYouText: string = '';

	contactGroups: ManyToManyCollection<ContactGroup> = new ManyToManyCollection<ContactGroup>(this, 'business/forms/OptInForm', 'contact_groups', 'business/contacts/ContactGroup', ContactGroup.manager, 'optInForm');
}

export default OptInFormGenerated;