import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import FieldEditor, { FieldEditorType, stringArrayToDataSource, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import OptInFormField, { OptInFormContactField } from '@business/forms/OptInFormField';
import OptInForm from '@business/forms/OptInForm';
import { OptInFormFieldType } from '@business/forms/generated/OptInFormField.generated';
import TabContainer from '@xFrame4/components/admin/TabContainer';

interface OptInFormFieldAddEditFormProps extends EntityAddEditFormProps<OptInFormField>
{
    optInForm: OptInForm;
}

const OptInFormFieldAddEditForm: FunctionComponent<OptInFormFieldAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<OptInFormField>(props.entity ?? new OptInFormField());
    const [availableContactFields, setAvailableContactFields] = useState<OptInFormContactField[]>([]);

    /** Load additional data */
    useLoad(async () => {
        // load available contact field names
        setAvailableContactFields(await OptInFormField.listAvailableContactFields());
    });

    /** Save */
    const onSave = async () =>
    {
        entity.optInForm = props.optInForm;
        
        return await entity.save();
    }

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="contactField"
                        label={t('CONTACT_FIELD')}
                        isRequired={true}
                        dataSource={availableContactFields}
                        dataSourceValueMember="name"
                        dataSourceDisplayMember="name"
                        value={entity?.contactField}
                        onValueChanged={(field, value) => {
                            updateEntityField(field, value.name);
                            updateEntityField('type', value.type);
                            updateEntityField('source', value.source);
                        }}
                    />
                </Col>
                <Col lg={3}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="type"
                        label={t('TYPE')}
                        isRequired={true}
                        isEnabled={false}
                        dataSource={enumToDataSource(OptInFormFieldType, t)}
                        value={entity?.type}
                    />
                </Col>
                <Col lg={3}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="isRequired"
                        label={t('IS_REQUIRED')}
                        value={entity?.isRequired}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="label"
                        label={t('LABEL')}
                        isRequired={true}
                        value={entity?.label}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="placeholderText"
                        label={t('PLACEHOLDER_TEXT')}
                        value={entity?.placeholderText}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="rowNum"
                        label={t('ROW_NUM')}
                        isRequired={true}
                        value={entity?.rowNum}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="orderNoInRow"
                        label={t('ORDER_NO_IN_ROW')}
                        isRequired={true}
                        value={entity?.orderNoInRow}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="colSpan"
                        label={t('COL_SPAN')}
                        isRequired={true}
                        value={entity?.colSpan}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="listOptions"
                        label={t('LIST_OPTIONS')}
                        value={OptInFormField.listOptionsToText(entity?.listOptions)}
                        onValueChanged={(field, value) => updateEntityField(field, OptInFormField.parseListOptions(value))}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<OptInFormField>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('OPT_IN_FORM_FIELD').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.contactField}
                onSave={() => onSave()}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default OptInFormFieldAddEditForm;