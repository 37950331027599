import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import EmailTemplate, { EmailTemplateBaseTemplateFile, EmailTemplateBodyTemplateFile } from '@business/mailing/EmailTemplate';
import { EmailTemplateBodyTemplateSource } from '@business/mailing/generated/EmailTemplate.generated';

interface EmailTemplateAddEditFormProps extends EntityAddEditFormProps<EmailTemplate>
{

}

const EmailTemplateAddEditForm: FunctionComponent<EmailTemplateAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<EmailTemplate>(props.entity ?? new EmailTemplate());

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="baseTemplateFile"
                        label={t('BASE_TEMPLATE_FILE')}
                        isRequired={true}
                        dataSource={enumToDataSource(EmailTemplateBaseTemplateFile, t)}
                        value={entity?.baseTemplateFile}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="bodyTemplateSource"
                        label={t('BODY_TEMPLATE_SOURCE')}
                        isRequired={true}
                        dataSource={enumToDataSource(EmailTemplateBodyTemplateSource, t)}
                        value={entity?.bodyTemplateSource}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="bodyTemplateFile"
                        label={t('BODY_TEMPLATE_FILE')}
                        isRequired={entity.bodyTemplateSource === EmailTemplateBodyTemplateSource.FILE}
                        isVisible={entity.bodyTemplateSource === EmailTemplateBodyTemplateSource.FILE}
                        dataSource={enumToDataSource(EmailTemplateBodyTemplateFile, t)}
                        value={entity?.bodyTemplateFile}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: html */
    let cmpTabHtml =
        <>
            <Row>
                <Col lg={12}>
                    <FieldEditor
                        type={FieldEditorType.RichTextEditor}
                        field="bodyTemplateHtml"
                        label={t('HTML')}
                        isRequired={entity.bodyTemplateSource === EmailTemplateBodyTemplateSource.DB}
                        value={entity?.bodyTemplateHtml}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ''

    /** Render */
    return (
        <>
            <EntityAddEditForm<EmailTemplate>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('EMAIL_TEMPLATE').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.name}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {   
                            eventKey: 'html',
                            title: t('ADD_EDIT_TAB_EMAIL_TEMPLATE_HTML'),
                            content: cmpTabHtml,
                        },
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default EmailTemplateAddEditForm;