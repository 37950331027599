import { gql } from '@apollo/client';
import SegmentConditionGenerated, { SegmentConditionGeneratedEntityManager } from './generated/SegmentCondition.generated';
import client from '@graphql/apollo-client';

export class SegmentConditionEntityManager extends SegmentConditionGeneratedEntityManager<SegmentCondition>
{
    constructor()
    {
        super({
            createEntity: () => new SegmentCondition(),
        });
    }
}

export class SegmentCondition extends SegmentConditionGenerated
{
    static manager: SegmentConditionEntityManager = new SegmentConditionEntityManager();

    /**
     * Get a list of possible field names for a segment condition.
     */
    static async getFieldNames()
    {   
        let query = `
            query GetPossibleFieldNamesForSegmentCondition {
                getPossibleFieldNamesForSegmentCondition
            }
        `;

        let result = await client.query({
            query: gql(query),
        });

        return result.data.getPossibleFieldNamesForSegmentCondition as string[];
    }
}

export default SegmentCondition;
