import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import Contact from '@business/contacts/Contact';
import EmailCampaign from '@business/mailing/EmailCampaign';
import AutomatedJourney from '@business/mailing/AutomatedJourney';
import OptInForm from '@business/forms/OptInForm';


export class ContactGroupGeneratedEntityManager<B extends ContactGroupGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'ContactGroup',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'description', type: BusinessEntityFieldType.Text },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'contactGroups',
        });
    }

}
export class ContactGroupGenerated extends BusinessEntity
{
	name!: string;
	description: string = '';

	contacts: ManyToManyCollection<Contact> = new ManyToManyCollection<Contact>(this, 'business/contacts/ContactGroup', 'contacts', 'business/contacts/Contact', Contact.manager, 'contactGroup');
	emailCampaigns: ManyToManyCollection<EmailCampaign> = new ManyToManyCollection<EmailCampaign>(this, 'business/contacts/ContactGroup', 'email_campaigns', 'business/mailing/EmailCampaign', EmailCampaign.manager, 'contactGroup');
	automatedJourneys: ManyToManyCollection<AutomatedJourney> = new ManyToManyCollection<AutomatedJourney>(this, 'business/contacts/ContactGroup', 'automated_journeys', 'business/mailing/AutomatedJourney', AutomatedJourney.manager, 'contactGroup');
	optInForms: ManyToManyCollection<OptInForm> = new ManyToManyCollection<OptInForm>(this, 'business/contacts/ContactGroup', 'opt_in_forms', 'business/forms/OptInForm', OptInForm.manager, 'contactGroup');
}

export default ContactGroupGenerated;