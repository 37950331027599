import client from '@graphql/apollo-client';
import { gql } from '@apollo/client';
import ContactGroup from '@business/contacts/ContactGroup';

export class FenyoHelper
{
    static async importFenyoContactsAndHistories(file: File, contactGroup: ContactGroup)
    {
        let query = `
            mutation ImportFenyoContactsAndHistories($file: Upload!, $contactGroupId: Int) {
                importFenyoContactsAndHistories(file: $file, contactGroupId: $contactGroupId) {
                    success
                }
            }
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                file: file,
                contactGroupId: contactGroup?.id,
            },
        });
        
        return result.data.importFenyoContactsAndHistories.success as boolean;
    }
}

export default FenyoHelper;