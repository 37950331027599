import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import Country from '@business/addresses/Country';
import ContactGroup from '@business/contacts/ContactGroup';

export enum ContactGender
{
    MALE = 'MALE',
    FEMALE = 'FEMALE',

}

export class ContactGeneratedEntityManager<B extends ContactGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'Contact',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'dateAdded', type: BusinessEntityFieldType.DateTime, isInput: false },
				{ name: 'email', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'firstName', type: BusinessEntityFieldType.VarChar },
				{ name: 'lastName', type: BusinessEntityFieldType.VarChar },
				{ name: 'name', type: BusinessEntityFieldType.VarChar },
				{ name: 'phone', type: BusinessEntityFieldType.VarChar },
				{ name: 'address', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'city', type: BusinessEntityFieldType.VarChar },
				{ name: 'region', type: BusinessEntityFieldType.VarChar },
				{ name: 'zipCode', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'country', type: BusinessEntityFieldType.ForeignKey, inputName: 'countryCode', inputProperty: 'country.code', relatedManager: Country.manager },
				{ name: 'gender', type: BusinessEntityFieldType.VarChar },
				{ name: 'dateOfBirth', type: BusinessEntityFieldType.Date },
				{ name: 'isConfirmed', type: BusinessEntityFieldType.Boolean },
				{ name: 'customFieldValues', type: BusinessEntityFieldType.Json, isInput: false },
				{ name: 'confirmationToken', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'unsubscribeToken', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'language', type: BusinessEntityFieldType.VarChar },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'contacts',
        });
    }

}
export class ContactGenerated extends BusinessEntity
{
	dateAdded!: string;
	email!: string;
	firstName: string = '';
	lastName: string = '';
	name: string = '';
	phone: string = '';
	address: string = '';
	city: string = '';
	region: string = '';
	zipCode: string = '';
	country: Country | null = null;
	gender: ContactGender | null = null;
	dateOfBirth: string | null = null;
	isConfirmed: boolean = false;
	customFieldValues: any | null = null;
	confirmationToken: string = '';
	unsubscribeToken!: string;
	language: string = '';

	contactGroups: ManyToManyCollection<ContactGroup> = new ManyToManyCollection<ContactGroup>(this, 'business/contacts/Contact', 'contact_groups', 'business/contacts/ContactGroup', ContactGroup.manager, 'contact');
}

export default ContactGenerated;