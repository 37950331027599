import { FunctionComponent, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import AddEditForm, { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import Contact, { ColumnNamesAndFieldNamesForMapping } from '@business/contacts/Contact';
import ImportMapper from './ImportMapper';
import ContactGroup from '@business/contacts/ContactGroup';

interface ImportContactsFormProps
{
    onClose: () => void;
    onImported: () => void;
}

const ImportContactsForm: FunctionComponent<ImportContactsFormProps> = (props) =>
{
    const t = useTranslate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [markAsConfirmed, setMarkAsConfirmed] = useState<boolean>(false);
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);
    const [contactGroup, setContactGroup] = useState<ContactGroup>();
    const [columnNamesAndFieldsNamesForMapping, setColumnNamesAndFieldsNamesForMapping] = useState<ColumnNamesAndFieldNamesForMapping | null>(null);
    const [columnNameToFieldNameMap, setColumnNameToFieldNameMap] = useState<{ [key: string]: string }> ({});

    useLoad(async () => {
        // load contact groups
        let contactGroupResult = await ContactGroup.manager.load();
        if (contactGroupResult) setContactGroups(contactGroupResult.entities);
    });

    /**
     * Initialize import.
     */
    const initImport = async (p_file: File) =>
    {
        setIsProcessing(true);

        // get column names and field names for mapping
        let cnfn = await Contact.getColumnNamesAndFieldNamesForMapping(p_file);
        console.log(cnfn);
        setColumnNamesAndFieldsNamesForMapping(cnfn);

        setIsProcessing(false);
    }

    /**
     * Validate form.
     */
    const onValidate = () : boolean =>
    {
        // check if the email field is mapped
        let isEmailMapped = false;
        for (let columnName in columnNameToFieldNameMap)
        {
            if (columnNameToFieldNameMap[columnName] === 'email')
            {
                isEmailMapped = true;
                break;
            }
        }

        if (!isEmailMapped)
        {
            setErrorMessage(t('EMAIL_FIELD_NOT_MAPPED'));
        }

        return isEmailMapped;
    }

    /**
     * Import contacts from Excel file.
     */
    const onImport = async () : Promise<boolean> =>
    {
        setIsProcessing(true);

        let success = await Contact.importContactsFromExcel(file!, columnNameToFieldNameMap, markAsConfirmed, contactGroup);

        setIsProcessing(false);

        if (success) props.onImported();

        return success;
    }
    
    /** Tab: General */
    let cmpTabGeneral =
        <>
            <Row>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="file"
                        label={t('FILE')}
                        isRequired={true}
                        onValueChanged={(field, file) => {
                            setFile(file);
                            initImport(file);
                        }}
                    />
                </Col>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="contactGroup"
                        label={t('CONTACT_GROUP')}
                        isRequired={true}
                        dataSource={contactGroups}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="name"
                        value={contactGroup}
                        onValueChanged={(field, contactGroup) => setContactGroup(contactGroup)}
                    />
                </Col>
                <Col lg={4}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="markAsConfirmed"
                        label={t('MARK_AS_CONFIRMED')}
                        value={markAsConfirmed}
                        onValueChanged={(field, value) => setMarkAsConfirmed(value)}
                    />
                </Col>
            </Row>
            { columnNamesAndFieldsNamesForMapping &&
            <Row>
                <Col>
                    <ImportMapper 
                        columnNames={columnNamesAndFieldsNamesForMapping.columnNames}
                        fieldNames={[...columnNamesAndFieldsNamesForMapping.contactFieldNames!, ...columnNamesAndFieldsNamesForMapping.customFieldNames]}
                        onMappingChanged={(columnNameToFieldNameMap) => setColumnNameToFieldNameMap(columnNameToFieldNameMap)}
                    />
                </Col>
            </Row>
            }
            { isProcessing &&
                <Row className="my-2">
                    <Col className="text-center">
                        <Spinner className="text-primary" />
                    </Col>
                </Row>
            }
        </>
    
    /** Render */
    return (
        <>
            <AddEditForm 
                mode={AddEditFormMode.Add}
                title={t('IMPORT_CONTACTS')}
                saveButtonLabel={t('IMPORT')}
                errorMessage={errorMessage}
                onValidate={() => onValidate()}
                onSave={() => onImport()}
                onClose={() => props.onClose()}
            >
            <TabContainer
                tabs={[
                    {
                        eventKey: 'general',
                        title: t('ADD_EDIT_TAB_GENERAL'),
                        content: cmpTabGeneral,
                    }
                ]}
            />
            </AddEditForm>
        </>
    );
}

export default ImportContactsForm;