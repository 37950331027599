import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';


export enum SmtpSetupEncryption
{
    TLS = 'TLS',
    SSL = 'SSL',

}

export class SmtpSetupGeneratedEntityManager<B extends SmtpSetupGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'SmtpSetup',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'host', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'port', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'username', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'password', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'encryption', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'isDefault', type: BusinessEntityFieldType.Boolean },
				{ name: 'fromAddress', type: BusinessEntityFieldType.VarChar },
				{ name: 'fromName', type: BusinessEntityFieldType.VarChar },
				{ name: 'dkimDomain', type: BusinessEntityFieldType.VarChar },
				{ name: 'dkimPrivateKeyLocation', type: BusinessEntityFieldType.VarChar },
				{ name: 'dkimSelector', type: BusinessEntityFieldType.VarChar },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'smtpSetups',
        });
    }

}
export class SmtpSetupGenerated extends BusinessEntity
{
	name!: string;
	host!: string;
	port!: number;
	username!: string;
	password!: string;
	encryption!: SmtpSetupEncryption;
	isDefault: boolean = false;
	fromAddress: string = '';
	fromName: string = '';
	dkimDomain: string = '';
	dkimPrivateKeyLocation: string = '';
	dkimSelector: string = '';

}

export default SmtpSetupGenerated;