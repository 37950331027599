import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import EmailTemplate from '@business/mailing/EmailTemplate';
import EmailCampaign from '@business/mailing/EmailCampaign';
import AutomatedJourneyMessage from '@business/mailing/AutomatedJourneyMessage';


export class EmailMessageGeneratedEntityManager<B extends EmailMessageGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'EmailMessage',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'emailTemplate', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'emailTemplateId', inputProperty: 'emailTemplate.id', relatedManager: EmailTemplate.manager },
				{ name: 'subject', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'preheader', type: BusinessEntityFieldType.VarChar },
				{ name: 'body', type: BusinessEntityFieldType.Text, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'emailMessages',
        });
    }

}
export class EmailMessageGenerated extends BusinessEntity
{
	emailTemplate!: EmailTemplate;
	subject: string = '';
	preheader: string = '';
	body: string = '';

	splitTestEmailCampaigns: ManyToManyCollection<EmailCampaign> = new ManyToManyCollection<EmailCampaign>(this, 'business/mailing/EmailMessage', 'split_test_email_campaigns', 'business/mailing/EmailCampaign', EmailCampaign.manager, 'splitTestEmailCampaignEmailMessage');
	splitTestAutomatedJourneyMessages: ManyToManyCollection<AutomatedJourneyMessage> = new ManyToManyCollection<AutomatedJourneyMessage>(this, 'business/mailing/EmailMessage', 'split_test_automated_journey_messages', 'business/mailing/AutomatedJourneyMessage', AutomatedJourneyMessage.manager, 'splitTestAutomatedJourneyMessageEmailMessage');
}

export default EmailMessageGenerated;