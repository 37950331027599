import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import EmailCampaign from '@business/mailing/EmailCampaign';
import AutomatedJourney from '@business/mailing/AutomatedJourney';


export class SegmentGeneratedEntityManager<B extends SegmentGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'Segment',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'description', type: BusinessEntityFieldType.Text },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'segments',
        });
    }

}
export class SegmentGenerated extends BusinessEntity
{
	name!: string;
	description: string = '';

	emailCampaigns: ManyToManyCollection<EmailCampaign> = new ManyToManyCollection<EmailCampaign>(this, 'business/contacts/Segment', 'email_campaigns', 'business/mailing/EmailCampaign', EmailCampaign.manager, 'segment');
	automatedJourneys: ManyToManyCollection<AutomatedJourney> = new ManyToManyCollection<AutomatedJourney>(this, 'business/contacts/Segment', 'automated_journeys', 'business/mailing/AutomatedJourney', AutomatedJourney.manager, 'segment');
}

export default SegmentGenerated;