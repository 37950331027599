import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import Country from '@business/addresses/Country';


export class CompanyGeneratedEntityManager<B extends CompanyGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'Company',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'registrationNumber', type: BusinessEntityFieldType.VarChar },
				{ name: 'email', type: BusinessEntityFieldType.VarChar },
				{ name: 'website', type: BusinessEntityFieldType.VarChar },
				{ name: 'phone', type: BusinessEntityFieldType.VarChar },
				{ name: 'address', type: BusinessEntityFieldType.VarChar },
				{ name: 'city', type: BusinessEntityFieldType.VarChar },
				{ name: 'region', type: BusinessEntityFieldType.VarChar },
				{ name: 'zipCode', type: BusinessEntityFieldType.VarChar, isInput: false },
				{ name: 'country', type: BusinessEntityFieldType.ForeignKey, inputName: 'countryCode', inputProperty: 'country.code', relatedManager: Country.manager },
				{ name: 'replyToName', type: BusinessEntityFieldType.VarChar },
				{ name: 'replyToEmail', type: BusinessEntityFieldType.VarChar },
				{ name: 'isConfirmationRequired', type: BusinessEntityFieldType.Boolean },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'companies',
        });
    }

}
export class CompanyGenerated extends BusinessEntity
{
	name!: string;
	registrationNumber: string | null = null;
	email: string | null = null;
	website: string | null = null;
	phone: string | null = null;
	address: string | null = null;
	city: string | null = null;
	region: string | null = null;
	zipCode: string | null = null;
	country: Country | null = null;
	replyToName: string | null = null;
	replyToEmail: string | null = null;
	isConfirmationRequired: boolean = false;

}

export default CompanyGenerated;