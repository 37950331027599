import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';

export type StatDetailsRowData = any[];

interface StatDetailsRowProps
{
    statDetailsRowData: StatDetailsRowData;
}

/**
 * Component: a row displaying data for a stats detail (eg: email opens for contact, url click count etc.)
 */
const StatsDetailsRow: FunctionComponent<StatDetailsRowProps> = (props) =>
{
    /** Render */
    return (
        <Row className="g-0 py-1 mb-1 border-bottom">
            {
                props.statDetailsRowData.map((statValue, index) =>
                    <Col key={index} className="text-center">{statValue}</Col>
                )
            }
        </Row>
    );
}

export default StatsDetailsRow;