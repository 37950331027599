import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import CustomFieldGroup from '@business/fields/CustomFieldGroup';

interface CustomFieldGroupAddEditFormProps extends EntityAddEditFormProps<CustomFieldGroup>
{

}

const CustomFieldGroupAddEditForm: FunctionComponent<CustomFieldGroupAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<CustomFieldGroup>(props.entity ?? new CustomFieldGroup());

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="orderNo"
                        label={t('ORDER_NO')}
                        isRequired={true}
                        value={entity?.orderNo}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<CustomFieldGroup>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('CUSTOM_FIELD_GROUP').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.name}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default CustomFieldGroupAddEditForm;