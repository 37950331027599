import { CustomFieldValue } from '@business/fields/CustomField';
import { CustomFieldType } from '@business/fields/generated/CustomField.generated';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { FunctionComponent } from 'react';

interface CustomFieldValueEditorProps
{
    customFieldValue: CustomFieldValue;
    onValueChanged: (value: any) => void;
}

const CustomFieldValueEditor: FunctionComponent<CustomFieldValueEditorProps> = (props) =>
{
    // get field editor type
    let fieldEditorType = FieldEditorType.Text;
    switch (props.customFieldValue.customField.type)
    {
        case CustomFieldType.NUMBER:
            fieldEditorType = FieldEditorType.Number;
            break;
        case CustomFieldType.DATE:
            fieldEditorType = FieldEditorType.Date;
            break;
        case CustomFieldType.BOOLEAN:
            fieldEditorType = FieldEditorType.Checkbox;
    }
    
    /** Render */
    return (
        <>
            <FieldEditor 
                type={fieldEditorType}
                field={props.customFieldValue.customField.name}
                label={props.customFieldValue.customField.name}
                isRequired={props.customFieldValue.customField.isRequired}
                value={props.customFieldValue.value}
                onValueChanged={(field, value) => props.onValueChanged(value)}
            />
        </>
    );
}

export default CustomFieldValueEditor;