import { FunctionComponent, useState } from 'react';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import Wizard from '@xFrame4/components/admin/Wizard';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import EmailCampaign, { EmailCampaignScheduleType } from '@business/mailing/EmailCampaign';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import EmailTemplate from '@business/mailing/EmailTemplate';
import EmailMessage from '@business/mailing/EmailMessage';
import EmailMessageEditor from './EmailMessageEditor';
import { Col, Row } from 'react-bootstrap';
import { now } from '@xFrame4/common/Functions';
import ContactGroup from '@business/contacts/ContactGroup';
import Segment from '@business/contacts/Segment';
import SmtpSetup from '@business/mailing/SmtpSetup';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';

interface EmailCampaignWizardProps
{
    emailCampaign?: EmailCampaign;
    afterFinish?: (mode: AddEditFormMode, entity: EmailCampaign) => void;
}

const EmailCampaignWizard: FunctionComponent<EmailCampaignWizardProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.emailCampaign?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [emailCampaign, setEmailCampaign, updateEmailCampaignField] = useUpdateEntityFields<EmailCampaign>(props.emailCampaign ?? new EmailCampaign());
    const [emailMessage, setEmailMessage] = useState<EmailMessage>(emailCampaign?.emailMessage);
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);
    const [selectedContactGroups, setSelectedContactGroups] = useState<ContactGroup[]>([]);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [smtpSetups, setSmtpSetups] = useState<SmtpSetup[]>([]);
    const [scheduleType, setScheduleType] = useState<EmailCampaignScheduleType>(EmailCampaignScheduleType.EMAIL_CAMPAIGN_SCHEDULE_TYPE_IMMEDIATELY);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    /** Load data */
    useLoad(async () => {
        // load contact groups
        let contactGroupResult = await ContactGroup.manager.load();
        if (contactGroupResult) setContactGroups(contactGroupResult.entities);

        // load selected contact groups
        if (mode === AddEditFormMode.Edit)
        {
            let selectedContactGroupsResult = await emailCampaign?.contactGroups.load();
            if (selectedContactGroupsResult) setSelectedContactGroups(selectedContactGroupsResult.entities);
        }

        // load segments
        let segmentResult = await Segment.manager.load();
        if (segmentResult) setSegments(segmentResult.entities);

        // load selected segments
        if (mode === AddEditFormMode.Edit)
        {
            let selectedSegmentsResult = await emailCampaign?.segments.load();
            if (selectedSegmentsResult) setSelectedSegments(selectedSegmentsResult.entities);
        }

        // load SMTP setups
        let smtpSetupResult = await SmtpSetup.manager.load();
        if (smtpSetupResult) setSmtpSetups(smtpSetupResult.entities);
    });

    /** Handle wizard step changed event. */
    const onStepChanged = async (previousStep: number, currentStep: number) =>
    {
        if (currentStep == 3 && previousStep == 2)
        {
            // create the draft campaign
            if (emailCampaign.id == null) 
            {
                setIsProcessing(true);
                let draftEmailCampaign = await EmailCampaign.createDraft(emailMessage.emailTemplate, emailCampaign.name, emailMessage.body);
                if (draftEmailCampaign) 
                {
                    // update the email message on the draft campaign
                    draftEmailCampaign.emailMessage.subject = emailMessage.subject;
                    draftEmailCampaign.emailMessage.preheader = emailMessage.preheader;
                    await draftEmailCampaign.emailMessage.save();
                    setEmailMessage(draftEmailCampaign.emailMessage);

                    // set the campaign in the state
                    setEmailCampaign(draftEmailCampaign);
                }
                setIsProcessing(false);
            }
        }
    }

    /** Validate the data before finishing. */
    const onValidate = () =>
    {
        // check if the schedule date is not in the past
        if (scheduleType === EmailCampaignScheduleType.EMAIL_CAMPAIGN_SCHEDULE_TYPE_LATER)
        {
            if (emailCampaign?.sendDate && emailCampaign.sendDate < now())
            {
                setErrorMessage(t('EMAIL_CAMPAIGN_SCHEDULE_LATER_SEND_DATE_IN_PAST'));
                return false;
            }
        }
        
        return true;
    }

    /** Handle finishing the wizard. */
    const onFinish = async () =>
    {
        let scheduledCampaign: EmailCampaign | null = null;

        // save the email message
        await emailMessage?.save();

        // set recipients
        if (selectedContactGroups.length > 0) await emailCampaign.contactGroups.set(selectedContactGroups);
        if (selectedSegments.length > 0) await emailCampaign.segments.set(selectedSegments);

        // save the campaign (update the draft)
        await emailCampaign?.save();
        
        // schedule the campaign (send immediately or send later)
        if (scheduleType === EmailCampaignScheduleType.EMAIL_CAMPAIGN_SCHEDULE_TYPE_IMMEDIATELY)
        {
            scheduledCampaign = await emailCampaign?.scheduleForImmediateSending();
            if (scheduledCampaign) setEmailCampaign(scheduledCampaign);
        }
        else if (scheduleType === EmailCampaignScheduleType.EMAIL_CAMPAIGN_SCHEDULE_TYPE_LATER)
        {
            scheduledCampaign = await emailCampaign?.schedule(emailCampaign.sendDate!);
            if (scheduledCampaign) setEmailCampaign(scheduledCampaign);
        }

        // was the campaign scheduled?
        let success = scheduledCampaign != null;

        // call the after finish callback
        if (success && props.afterFinish) props.afterFinish(AddEditFormMode.Edit, scheduledCampaign!);

        return success
    }

    /** Step 1 */
    let cmpStep1 = <>
        <Row>
            <Col>
                <FieldEditor 
                    type={FieldEditorType.Text}
                    field="name"
                    label={t('NAME')}
                    isRequired={true}
                    value={emailCampaign?.name}
                    onValueChanged={(field, value) => updateEmailCampaignField(field, value)}
                />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <FieldEditor
                    type={FieldEditorType.Checkbox}
                    field="isSplitTest"
                    label={t('IS_SPLIT_TEST')}
                    value={emailCampaign?.isSplitTest}
                    onValueChanged={(field, value) => updateEmailCampaignField(field, value)}
                />
            </Col>
        </Row>
    </>;

    /** Step 2 */
    let cmpStep2 = <>
        <EmailMessageEditor 
            emailMessage={emailMessage}
            onEmailMessageChanged={(emailMessage) => setEmailMessage(emailMessage)}
        />
    </>;

    /** Step 3 */
    let cmpStep3 = <>
        <Row>
            <Col lg={6}>
                <FieldEditor 
                    type={FieldEditorType.List}
                    field="contactGroups"
                    label={t('CONTACT_GROUPS')}
                    dataSource={contactGroups}
                    dataSourceValueMember="id"
                    dataSourceDisplayMember="name"
                    value={selectedContactGroups}
                    onValueChanged={(field, value) => setSelectedContactGroups(value)}
                />
            </Col>
            <Col lg={6}>
                <FieldEditor 
                    type={FieldEditorType.List}
                    field="segments"
                    label={t('SEGMENTS')}
                    dataSource={segments}
                    dataSourceValueMember="id"
                    dataSourceDisplayMember="name"
                    value={selectedSegments}
                    onValueChanged={(field, value) => setSelectedSegments(value)}
                />
            </Col>
        </Row>
    </>;

    /** Step 4 */
    let cmpStep4 = <>
        <Row>
            <Col lg={6}>
                <FieldEditor 
                    type={FieldEditorType.Select}
                    field="smtpSetup"
                    label={t('SMTP_SETUP')}
                    dataSource={smtpSetups}
                    dataSourceValueMember="id"
                    dataSourceDisplayMember="name"
                    value={emailCampaign?.smtpSetup}
                    onValueChanged={(field, value) => updateEmailCampaignField(field, value)}
                />
            </Col>
        </Row>
    </>;

    /** Step 5 */
    let cmpStep5 = <>
        <Row>
            <Col lg={6}>
                <FieldEditor 
                    type={FieldEditorType.Select}
                    field="scheduleType"
                    label={t('EMAIL_CAMPAIGN_SCHEDULE_TYPE')}
                    isRequired={true}
                    dataSource={enumToDataSource(EmailCampaignScheduleType, t)}
                    value={scheduleType}
                    onValueChanged={(field, value) => setScheduleType(value.value)}
                />
            </Col>
            <Col>
                <FieldEditor
                    type={FieldEditorType.DateTimeLocal}
                    field="sendDate"
                    label={t('EMAIL_CAMPAIGN_SCHEDULE_LATER_SEND_DATE')}
                    isVisible={scheduleType === EmailCampaignScheduleType.EMAIL_CAMPAIGN_SCHEDULE_TYPE_LATER}
                    isRequired={scheduleType === EmailCampaignScheduleType.EMAIL_CAMPAIGN_SCHEDULE_TYPE_LATER}
                    value={emailCampaign?.sendDate}
                    onValueChanged={(field, value) => updateEmailCampaignField(field, value)}
                />
            </Col>
        </Row>
    </>;
    
    /** Render */
    return (
        <>
            <Wizard 
                title={t('EMAIL_CAMPAIGN_WIZARD_TITLE')}
                steps={
                    [
                        { title: t('EMAIL_CAMPAIGN_WIZARD_STEP_1_GENERAL'), jsxContent: cmpStep1 },
                        { title: t('EMAIL_CAMPAIGN_WIZARD_STEP_2_EMAIL_MESSAGE'), jsxContent: cmpStep2 },
                        { title: t('EMAIL_CAMPAIGN_WIZARD_STEP_3_RECIPIENTS'), jsxContent: cmpStep3 },
                        { title: t('EMAIL_CAMPAIGN_WIZARD_STEP_4_SMTP_SETUP'), jsxContent: cmpStep4 },
                        { title: t('EMAIL_CAMPAIGN_WIZARD_STEP_5_EMAIL_SCHEDULING'), jsxContent: cmpStep5 },
                    ]
                }
                isProcessing={isProcessing}
                errorMessage={errorMessage}
                onStepChanged={(previousStep, currentStep) => onStepChanged(previousStep, currentStep)}
                onValidate={() => onValidate()}
                onClose={() => props.afterFinish?.(mode, emailCampaign!)}
                onFinish={() => onFinish()}
            />
        </>
    );
}

export default EmailCampaignWizard;