import { gql } from '@apollo/client';
import ContactHistoryGenerated, { ContactHistoryGeneratedEntityManager } from './generated/ContactHistory.generated';
import client from '@graphql/apollo-client';
import { ColumnNamesAndFieldNamesForMapping } from './Contact';
import CustomFieldValueManager from './CustomFieldValueManager';

export class ContactHistoryEntityManager extends ContactHistoryGeneratedEntityManager<ContactHistory>
{
    constructor()
    {
        super({
            createEntity: () => new ContactHistory(),
        });
    }
}

export class ContactHistory extends ContactHistoryGenerated
{
    static manager: ContactHistoryEntityManager = new ContactHistoryEntityManager();
    customFieldValueManager: CustomFieldValueManager = new CustomFieldValueManager(this, 'ContactHistory');

    // override
    copy()
    {
        let copy = super.copy();

        // update the entity for the CustomFieldValueManager
        copy.customFieldValueManager.entity = copy;

        return copy;
    }

    /**
     * Prepare for contact import. Return the mapping for Excel column/field names.
     * 
     * @param file 
     * @returns The mapping.
     */
    static async getColumnNamesAndFieldNamesForMapping(file: File)
    {
        let query = `
            query GetContactHistoryColumnNamesAndFieldNamesForMapping($file: Upload!) {
                getContactHistoryColumnNamesAndFieldNamesForMapping(file: $file)
            }
        `;
            
        let result = await client.query({
            query: gql(query),
            variables: {
                file: file,
            },
        });

        return JSON.parse(result.data.getContactHistoryColumnNamesAndFieldNamesForMapping) as ColumnNamesAndFieldNamesForMapping;
    }

    /**
     * Import contacts from Excel file.
     * 
     * @param file The Excel file.
     * @param columnNameToFieldNameMap The mapping for Excel column/field names. An example: { 'Event Name': "eventName", 'Event Date': "eventDate" }
     */
    static async importContactsFromExcel(file: File, columnNameToFieldNameMap: { [key: string]: string })
    {
        let query = `
            mutation ImportContactHistoryFromExcel($file: Upload!, $columnNameToFieldNameMap: JSON!) {
                importContactHistoryFromExcel(file: $file, columnNameToFieldNameMap: $columnNameToFieldNameMap) {
                    success
                }
            }
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                file: file,
                columnNameToFieldNameMap: JSON.stringify(columnNameToFieldNameMap),
            },
        });

        return result.data.importContactHistoryFromExcel.success as boolean;
    }
}

export default ContactHistory;