import SmtpSetupGenerated, { SmtpSetupGeneratedEntityManager } from './generated/SmtpSetup.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class SmtpSetupEntityManager extends SmtpSetupGeneratedEntityManager<SmtpSetup>
{
    constructor()
    {
        super({
            createEntity: () => new SmtpSetup(),
        });
    }
}

export class SmtpSetup extends SmtpSetupGenerated
{
    static _manager: SmtpSetupEntityManager;
    static get manager(): SmtpSetupEntityManager
    {
        if (!this._manager) this._manager = new SmtpSetupEntityManager();
        return this._manager;
    }
}

export default SmtpSetup;