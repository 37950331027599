import { FunctionComponent, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

interface WizardStepProps
{
    step: WizardStepType
}

const WizardStep = forwardRef((props: WizardStepProps, ref) => 
{
    const formRef = useRef<HTMLFormElement>(null);
    const [validated, setValidated] = useState<boolean>(false);

    // https://react.dev/reference/react/useImperativeHandle
    useImperativeHandle(ref, () => ({
        validate: () => {
            const isValid = formRef.current?.checkValidity() ?? false;
            setValidated(true);
            return isValid;
        },
    }));

    /** Render */
    return (
        <>
            <Form ref={formRef} noValidate validated={validated} onSubmit={(event) => event.preventDefault()}>
                {props.step.jsxContent}
            </Form>
        </>
    );
});

export type WizardStepType = {
    /** The title of the step. */
    title: string,
    /** The content of the step. */
    jsxContent: JSX.Element,
}

export default WizardStep;