import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import CustomFieldGroup from '@business/fields/CustomFieldGroup';

export enum CustomFieldType
{
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',

}
export enum CustomFieldAvailableFor
{
    ALL = 'ALL',
    CONTACT = 'CONTACT',
    CONTACT_HISTORY = 'CONTACT_HISTORY',

}

export class CustomFieldGeneratedEntityManager<B extends CustomFieldGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'CustomField',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'type', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'isRequired', type: BusinessEntityFieldType.Boolean, isRequiredInput: true },
				{ name: 'orderNo', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'customFieldGroup', type: BusinessEntityFieldType.ForeignKey, inputName: 'customFieldGroupId', inputProperty: 'customFieldGroup.id', relatedManager: CustomFieldGroup.manager },
				{ name: 'availableFor', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'customFields',
        });
    }

}
export class CustomFieldGenerated extends BusinessEntity
{
	name!: string;
	type: CustomFieldType = CustomFieldType.TEXT;
	isRequired: boolean = false;
	orderNo: number = 0;
	customFieldGroup: CustomFieldGroup | null = null;
	availableFor: CustomFieldAvailableFor = CustomFieldAvailableFor.ALL;

}

export default CustomFieldGenerated;