import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import EntityAddEditForm, { EntityAddEditFormProps } from '../EntityAddEditForm';
import { useUpdateEntityFields } from '../../Hooks';
import { AddEditFormMode } from '../AddEditForm';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import Group from '@xFrame4/business/users/Group';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import TabContainer from '../TabContainer';
import Permission from '@xFrame4/business/users/Permission';
import PermissionsEditor from './PermissionsEditor';

interface GroupAddEditFormProps extends EntityAddEditFormProps<Group>
{

}

const GroupAddEditForm: FunctionComponent<GroupAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<Group>(props.entity ?? new Group());
    const [permissions, setPermissions] = useState<Permission[]>([]);
    
    useLoad(async () => {
        // load the existing permissions
        let permissionResult = await entity?.permissions.load();
        if (permissionResult) setPermissions(permissionResult.entities);
    });

    /** Save */
    const onSave = async () => 
    {
        // save the entity
        let saveResult = await entity?.save();

        // save the permissions
        if (saveResult)
        {
        await entity.permissions.set(permissions);
        }
        
        return saveResult;
    }

    /** Tab: general */
    let cmpTabGeneral =
        <>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>;
    
    /** Tab: permissions */
    let cmpTabPermissions =
        <>
            <PermissionsEditor 
                selectedPermissions={permissions}
                onChange={(permissions) => setPermissions(permissions)}
            />
        </>;

    /** Render */
    return (
        <>
            <EntityAddEditForm<Group>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t(Group.manager.name)}
                titleEdit={t('EDIT') + ': ' + `${entity?.name}`}
                onClose={() => props.afterEdit?.(mode, entity)}
                onSave={() => onSave()}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {
                            eventKey: 'permissions',
                            title: t('ADD_EDIT_TAB_PERMISSIONS'),
                            content: cmpTabPermissions,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default GroupAddEditForm;