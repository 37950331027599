import CountryGenerated, { CountryGeneratedEntityManager } from './generated/Country.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class CountryEntityManager extends CountryGeneratedEntityManager<Country>
{
    constructor()
    {
        super({
            createEntity: () => new Country(),
        });
    }
}

export class Country extends CountryGenerated
{
    static _manager: CountryEntityManager;
    static get manager(): CountryEntityManager
    {
        if (!this._manager) this._manager = new CountryEntityManager();
        return this._manager;
    }
}

export default Country;