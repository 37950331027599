import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import AutomatedJourney from '@business/mailing/AutomatedJourney';
import EmailMessage from '@business/mailing/EmailMessage';
import User from '@xFrame4/business/users/User';

export enum AutomatedJourneyMessageDelayUnit
{
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    DAYS = 'DAYS',
    MONTHS = 'MONTHS',

}
export enum AutomatedJourneyMessageSendAfterEvent
{
    BIRTHDAY = 'BIRTHDAY',
    CHECKOUT = 'CHECKOUT',

}
export enum AutomatedJourneyMessageStatus
{
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',

}

export class AutomatedJourneyMessageGeneratedEntityManager<B extends AutomatedJourneyMessageGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'AutomatedJourneyMessage',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'automatedJourney', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'automatedJourneyId', inputProperty: 'automatedJourney.id', relatedManager: AutomatedJourney.manager },
				{ name: 'emailMessage', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'emailMessageId', inputProperty: 'emailMessage.id', relatedManager: EmailMessage.manager },
				{ name: 'orderNo', type: BusinessEntityFieldType.Integer },
				{ name: 'delay', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'delayUnit', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'sendAfterEvent', type: BusinessEntityFieldType.VarChar },
				{ name: 'status', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'user', type: BusinessEntityFieldType.ForeignKey, inputName: 'userId', inputProperty: 'user.id', relatedManager: User.manager },
				{ name: 'isSplitTest', type: BusinessEntityFieldType.Boolean },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'automatedJourneyMessages',
        });
    }

}
export class AutomatedJourneyMessageGenerated extends BusinessEntity
{
	automatedJourney!: AutomatedJourney;
	emailMessage!: EmailMessage;
	orderNo!: number;
	delay!: number;
	delayUnit!: AutomatedJourneyMessageDelayUnit;
	sendAfterEvent: AutomatedJourneyMessageSendAfterEvent | null = null;
	status!: AutomatedJourneyMessageStatus;
	user!: User;
	isSplitTest: boolean = false;

	splitTestMessages: ManyToManyCollection<EmailMessage> = new ManyToManyCollection<EmailMessage>(this, 'business/mailing/AutomatedJourneyMessage', 'split_test_messages', 'business/mailing/EmailMessage', EmailMessage.manager, 'splitTestAutomatedJourneyMessage');
}

export default AutomatedJourneyMessageGenerated;