import React from 'react';
import ReactDom from 'react-dom/client';
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { store } from '@redux/store';
import { Provider } from 'react-redux';
import App from './App';
import config from '@config';
import LoginView from '@xFrame4/components/admin/auth/LoginView';
import RegistrationView from '@xFrame4/components/admin/auth/RegistrationView';
import MyAccountView from '@xFrame4/components/admin/auth/MyAccountView';
import DashboardView from '@components/dashboard/DashboardView';
import AppSettingAddEditForm from '@components/settings/AppSettingAddEditForm';
import UserView from '@xFrame4/components/admin/auth/UserView';
import GroupView from '@xFrame4/components/admin/auth/GroupView';
import ContactView from '@components/contacts/ContactView';
import CountryView from '@components/addresses/CountryView';
import ContactGroupView from '@components/contacts/ContactGroupView';
import SmtpSetupView from '@components/mailing/SmtpSetupView';
import EmailTemplateView from '@components/mailing/EmailTemplateView';
import EmailCampaignView from '@components/mailing/EmailCampaignView';
import SegmentView from '@components/contacts/SegmentView';
import CustomFieldView from '@components/fields/CustomFieldView';
import CompanyAddEditForm from '@components/general/CompanyAddEditForm';
import AutomatedJourneyView from '@components/mailing/AutomatedJourneyView';
import FenyoImportForm from '@components/fenyo/FenyoImportForm';
import CustomFieldGroupView from '@components/fields/CustomFieldGroupView';
import OptInFormView from '@components/forms/OptInFormView';

run();

function run()
{
    const rootElement = document.getElementById('root');
    
    if (rootElement != null)
    {
        /**
         * React-Router remarks:
         * my-account, farm etc. can be nested inside the "/" route because the element <App> has an Outlet in one of its child elements
         * add, edit cannot be nested inside "asset-types", because  AssetTypeView has not Outlet for it
         */
        const root = ReactDom.createRoot(rootElement);
        root.render(
            <Provider store={store}>
                <BrowserRouter basename={config.reactRouterBaseName}>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route index={true} element={<DashboardView />} />

                            <Route path="my-account" element={<MyAccountView />} />
                            <Route path="users" element={<UserView />} />
                            <Route path="groups" element={<GroupView />} />

                            <Route path="settings" element={<AppSettingAddEditForm />} />

                            <Route path="contacts" element={<ContactView />} />
                            <Route path="contact-groups" element={<ContactGroupView />} />
                            <Route path="segments" element={<SegmentView />} />
                            
                            <Route path="my-contact-groups">
                                <Route path=":contactGroupId" element={<ContactView />} />
                            </Route>

                            <Route path="my-segments">
                                <Route path=":segmentId" element={<ContactView />} />
                            </Route>

                            <Route path="fenyo">
                                <Route path="fenyo-import" element={<FenyoImportForm />} />
                            </Route>

                            <Route path="custom-field-groups" element={<CustomFieldGroupView />} />
                            <Route path="custom-fields" element={<CustomFieldView />} />

                            <Route path="opt-in-forms" element={<OptInFormView />} />

                            <Route path="company" element={<CompanyAddEditForm />} />

                            <Route path="countries" element={<CountryView />} />

                            <Route path="email-campaigns" element={<EmailCampaignView />} /> 
                            <Route path="automated-journeys" element={<AutomatedJourneyView />} /> 
                            <Route path="email-templates" element={<EmailTemplateView />} />
                            <Route path="smtp-setups" element={<SmtpSetupView />} />
                        </Route>

                        <Route path="/login" element={<LoginView />} />
                    </Routes>
                </BrowserRouter>
            </Provider>,
        );
    }
}