import CustomFieldGroupGenerated, { CustomFieldGroupGeneratedEntityManager } from './generated/CustomFieldGroup.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class CustomFieldGroupEntityManager extends CustomFieldGroupGeneratedEntityManager<CustomFieldGroup>
{
    constructor()
    {
        super({
            createEntity: () => new CustomFieldGroup(),
        });
    }
}

export class CustomFieldGroup extends CustomFieldGroupGenerated
{
    static _manager: CustomFieldGroupEntityManager;
    static get manager(): CustomFieldGroupEntityManager
    {
        if (!this._manager) this._manager = new CustomFieldGroupEntityManager();
        return this._manager;
    }
}

export default CustomFieldGroup;