import { useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { uniqueId } from '@xFrame4/common/Functions';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { storeFilterForEntityView } from '@xFrame4/redux/entityManagementSlice';
import { useTranslate } from '@xFrame4/components/Hooks';
import { useHandleAddEdit, useLoadPaginatedEntities } from '@xFrame4/components/admin/EntityViewHooks';
import ConfirmDialog, { ConfirmDialogResult, ConfirmDialogType } from '@xFrame4/components/common/ConfirmDialog';
import EntityGrid from './EntityGrid';
import EntityPageTitle from './EntityPageTitle';
import EntityViewFilter, { FilterEditor } from './EntityViewFilter';
import BusinessEntity, { BusinessEntityFilter, BusinessEntityPaging, EntityManager } from '@xFrame4/business/base/BusinessEntity';

/**
 * These settings can be used to store the view settings in the Redux store.
 */
export type EntityViewSettings = {
    /** The view ID. This identifies the view in a list of settings. */
    id: string,
    /** The filters that were applied to the entities. It's handy when switching back and forth between views and we want to keep the filters. */
    filter: BusinessEntityFilter
}

/**
 * Grid handling info.
 */
export type EntityViewGridInfo<B extends BusinessEntity> = {
    columns: GridColumns<B>,
    onRowDoubleClick: (entity: B) => void
}

/**
 * Info aboout the current page of entities.
 */
export type EntityViewPageInfo = {
    /** The current page number. 0 based index. */
    page: number,
    /** The paging direction. */
    pagingDirection: EntityViewPagingDirection,
    /** The paging settings for loading the entities for the page. */
    businessEntityPaging: BusinessEntityPaging,
    /** Filter for the entities. */
    businessEntityFilter?: BusinessEntityFilter
}

/**
 * A button for the entity view.
 */
export type EntityViewButton = {
    text: string,
    variant: string,
    isEnabled?: boolean,
    onClick: () => void
}

/**
 * An action for the entity view.
 */
export type EntityViewAction<B extends BusinessEntity> = {
    label: string,
    icon: JSX.Element,
    isHidden?: (entity: B) => boolean,
    onClick: (entity: B) => void
}

/**
 * The delete confirnation dialog info.
 */
export type EntityDeleteConfirmDialogInfo<B extends BusinessEntity> = {
    title: (entity: B) => string,
    onConfirmed: (entity: B) => Promise<boolean>,
    afterDelete?: () => void
}

/**
 * The direction of the grid pagination.
 */
export enum EntityViewPagingDirection
{
    Forward,
    Backward,
    Reload
}

interface EntityViewProps<B extends BusinessEntity>
{
    isVisible: boolean;
    /** A unique ID for the view. */
    id: string;
    /** The title of the view. */
    title: string;
    /** The manager that handles the data loading. */
    manager: EntityManager<B>;
    /** A custom GraphQL query for the entity fields. If specified, nodes will be loaded with EntityManager.loadCustom(). */
    customQuery?: string,
    /** A filter that is always applied to the manager's load function. */
    baseFilter?: BusinessEntityFilter;
    /** How many entities to show on a page. */
    pageSize: number;
    /** Buttons on the left side. */
    buttonsLeft?: EntityViewButton[];
    /** Buttons on the right side */
    buttonsRight?: EntityViewButton[];
    /** Grid related info. */
    gridInfo: EntityViewGridInfo<B>,
    /** Filters for entity. Using Django Filter strings. */
    filterEditors?: FilterEditor[];
    /** Actions for the entity row. */
    actions?: EntityViewAction<B>[];
    /** Info about the entity delete confirmation dialog. */
    deleteConfirmDialogInfo?: EntityDeleteConfirmDialogInfo<B>;
    /** Is the delete button disabled? */
    isDeleteDisabled?: (entity: B) => boolean;
    /** The newly added entity */
    addedEntity?: B;
    /** The edited entity */
    editedEntity?: B;
}

function EntityView<B extends BusinessEntity>(props: EntityViewProps<B>)
{
    const t = useTranslate();
    const dispatch = useAppActionDispatch();
    const settingsFromRedux = useAppStateSelector(state => state.entityManagement.settings.find(s => s.id === props.id))
    const [isLoading, entities, setEntities, paginationInfo, pageReloadCursors, pageInfo, setPageInfo] = useLoadPaginatedEntities<B>(props.manager, props.baseFilter, settingsFromRedux?.filter, props.pageSize, props.customQuery);
    const [entityToDelete, setEntityToDelete] = useState<B>();
    
    /** Hook: handle add/edit */
    useHandleAddEdit<B>(props.addedEntity, props.editedEntity, setEntities, setPageInfo);

    /** Buttons to the left */
    let cmpButtonsLeft = props.buttonsLeft?.map((button, i) =>
        <Button 
            key={i} 
            variant={button.variant} 
            disabled={button.isEnabled !== undefined && !button.isEnabled}
            className="mb-2 me-1" 
            onClick={() => button.onClick()}
        >
            {button.text}
        </Button>
    );

    /** Buttons to the right */
    let cmpButtonsRight = props.buttonsRight?.map((button, i) =>
        <Button 
            key={i} 
            variant={button.variant} 
            disabled={button.isEnabled !== undefined && !button.isEnabled}
            className="mb-2 ms-1" 
            onClick={() => button.onClick()}
        >
            {button.text}
        </Button>
    );

    /** Filters */
    let cmpFilter = <></>;
    if (props.filterEditors)
    {
        cmpFilter = 
        <EntityViewFilter
            filterEditors={props.filterEditors}
            isLoading={isLoading}
            filter={settingsFromRedux?.filter} // load the filter from the Redux store (if it was stored there)
            onFilter={(filter) => {
                // set the page info
                setPageInfo({ 
                    page: 0, 
                    pagingDirection: EntityViewPagingDirection.Forward,
                    businessEntityPaging: {
                        first: props.pageSize
                    },
                    businessEntityFilter: filter 
                });

                // store the filter in the Redux store
                dispatch(storeFilterForEntityView({
                    id: props.id,
                    filter: filter
                }));
            }}
        />
    }

    /** Entity grid */
    let columns: GridColumns<B> = [
        {
            field: 'actions',
            headerName: t('ACTIONS'),
            type: 'actions',
            getActions: (params) => [
                <>
                    {(props.isDeleteDisabled === undefined || !props.isDeleteDisabled(params.row)) &&
                    <GridActionsCellItem
                        key={1}
                        icon={<DeleteIcon />}
                        label={props.deleteConfirmDialogInfo?.title(params.row) as string}
                        onClick={() => setEntityToDelete(params.row)}
                    />
                    }
                </>
                ,
                ...
                (
                    props.actions === undefined ? [] : props.actions.map(action => 
                    <GridActionsCellItem
                        key={uniqueId()}
                        icon={action.icon}
                        label={action.label}
                        hidden={action.isHidden !== undefined && action.isHidden(params.row)}
                        onClick={() => action.onClick(params.row)}
                    />)
                )
            ],
        }
    ]

    let cmpGrid =
        <EntityGrid<B>
            columns={[...props.gridInfo.columns, ...columns]}
            entities={entities}
            currentPage={pageInfo.page}
            pageSize={props.pageSize}
            rowCount={paginationInfo?.totalCount}
            isLoading={isLoading}
            onPageChange={(oldPage, newPage) => {
                console.log('EntityView: onPageChange: ' + oldPage + ' -> ' + newPage);
                let pagingDirection = oldPage < newPage ? EntityViewPagingDirection.Forward : EntityViewPagingDirection.Backward;
                
                if (pagingDirection == EntityViewPagingDirection.Forward)
                {
                    if (paginationInfo?.hasNextPage)
                    {
                        setPageInfo(
                            prev => ({ 
                                ...prev,
                                page: newPage, 
                                pagingDirection: pagingDirection,
                                businessEntityPaging: {
                                    first: props.pageSize,
                                    after: paginationInfo?.endCursor,
                                }
                            })
                        );
                    }
                }
                else
                {
                    let pageReloadCursor = pageReloadCursors.find(rc => rc.page == newPage);

                    if (pageReloadCursor !== undefined)
                    {
                        setPageInfo(
                            prev => ({ 
                                ...prev,
                                page: newPage, 
                                pagingDirection: pagingDirection,
                                businessEntityPaging: {
                                    first: props.pageSize,
                                    after: pageReloadCursor?.cursor,
                                }
                            })
                        );
                    }
                }
            }}
            onRowDoubleClick={props.gridInfo.onRowDoubleClick}
        />;

    /** Delete entity confirm dialog */
    let cmpDeleteConfirmDialog = <></>;
    if (props.deleteConfirmDialogInfo)
    {
        cmpDeleteConfirmDialog =
            <ConfirmDialog
                type={ConfirmDialogType.YesNo}
                title={entityToDelete !== undefined ? props.deleteConfirmDialogInfo?.title(entityToDelete) : ''}
                isOpen={entityToDelete !== undefined}
                onClose={(result) => {
                    if (result == ConfirmDialogResult.Yes)
                    {
                        deleteEntity();
                    }
                    else
                    {
                        setEntityToDelete(undefined);
                    }
                }}
            />
    }

    /** Delete entity wrapper function. */
    const deleteEntity = async () => 
    {
        // Delete
        let success = await props.deleteConfirmDialogInfo?.onConfirmed(entityToDelete!)
        setEntityToDelete(undefined);

        if (success)
        {
            // Fire the 'after delete' event
            if (props.deleteConfirmDialogInfo?.afterDelete) props.deleteConfirmDialogInfo.afterDelete();
            
            // Reload view
            setPageInfo(prev => ({
                ...prev,
            }));
        }
    };

    /** Render */
    return (
        <div className="entity-view" style={{display: props.isVisible ? 'block' : 'none'}}>
            <EntityPageTitle title={props.title} />
            
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <Row className="mb-4">
                                <Col sm={5}>
                                    {cmpButtonsLeft}
                                </Col>

                                <Col sm={7}>
                                    <div className="text-sm-end">
                                        {cmpButtonsRight}
                                    </div>
                                </Col>
                            </Row>

                            <div className="mb-2">
                                {cmpFilter}
                            </div>

                            {cmpGrid}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {cmpDeleteConfirmDialog}
        </div>
    );
}

EntityView.defaultProps =
{
    isVisible: true,
    pageSize: 10,
    buttonsLeft: [],
    buttonsRight: [],
}

export default EntityView;