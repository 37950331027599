import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import OptInForm from '@business/forms/OptInForm';

export enum OptInFormFieldSource
{
    CONTACT = 'CONTACT',
    CONTACT_HISTORY = 'CONTACT_HISTORY',
    CONTACT_CUSTOM_FIELD = 'CONTACT_CUSTOM_FIELD',
    CONTACT_HISTORY_CUSTOM_FIELD = 'CONTACT_HISTORY_CUSTOM_FIELD',

}
export enum OptInFormFieldType
{
    TEXT = 'TEXT',
    EMAIL = 'EMAIL',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    DROPDOWN_SINGLE = 'DROPDOWN_SINGLE',
    DROPDOWN_MULTIPLE = 'DROPDOWN_MULTIPLE',
    CHECK_BOX_SINGLE = 'CHECK_BOX_SINGLE',
    CHECK_BOX_MULTIPLE = 'CHECK_BOX_MULTIPLE',

}

export class OptInFormFieldGeneratedEntityManager<B extends OptInFormFieldGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'OptInFormField',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'optInForm', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'optInFormId', inputProperty: 'optInForm.id', relatedManager: OptInForm.manager },
				{ name: 'contactField', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'source', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'label', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'type', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'listOptions', type: BusinessEntityFieldType.Json },
				{ name: 'isRequired', type: BusinessEntityFieldType.Boolean },
				{ name: 'placeholderText', type: BusinessEntityFieldType.VarChar },
				{ name: 'rowNum', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'colSpan', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'orderNoInRow', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'optInFormFields',
        });
    }

}
export class OptInFormFieldGenerated extends BusinessEntity
{
	optInForm!: OptInForm;
	contactField!: string;
	source!: OptInFormFieldSource;
	label!: string;
	type!: OptInFormFieldType;
	listOptions: any | null = null;
	isRequired: boolean = false;
	placeholderText: string = '';
	rowNum!: number;
	colSpan!: number;
	orderNoInRow!: number;

}

export default OptInFormFieldGenerated;