import { FunctionComponent, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import FieldEditor, { FieldEditorType, stringArrayToDataSource } from '@xFrame4/components/common/FieldEditor';
import CustomField from '@business/fields/CustomField';

interface ColumnToFieldMapperProps
{
    columnNames: string[];
    fieldNames: string[];
    onChange: (columnName: string, fieldName: string) => void;
}

/**
 * Component: map an Excel column to a contact field or a custom contact field.
 */
const ColumnToFieldMapper: FunctionComponent<ColumnToFieldMapperProps> = (props) =>
{
    const t = useTranslate();
    const [fieldNames, setFieldNames] = useState<{value: string, display: string}[]>([]);
    const [columnName, setColumnName] = useState<string | null>(null);
    const [fieldName, setFieldName] = useState<string | null>(null);

    useLoad(async () => {
        // load possible field names
        let fieldNamesRaw = props.fieldNames;
        
        let customFieldNames: string[] = [];
        let customFieldResult = await CustomField.manager.load();
        if (customFieldResult)
        {
            customFieldNames = customFieldResult.entities.map((customField) => customField.name);
        }

        let fieldNames = fieldNamesRaw.map((fieldName) => {
            return {
                value: fieldName,
                display: !customFieldNames.includes(fieldName) ? t('CONTACT_FIELD_NAME_' + fieldName) : fieldName
            }
        });  
        setFieldNames(fieldNames);
    });
    
    /** Render */
    return (
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor 
                        type={FieldEditorType.Select}
                        field="columnName"
                        dataSource={stringArrayToDataSource(props.columnNames)}
                        label={t('COLUMN_NAME')}
                        onValueChanged={(field, value) => {
                            setColumnName(value.value);
                            props.onChange(value.value, fieldName!);
                        }}

                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor 
                        type={FieldEditorType.Select}
                        field="fieldName"
                        dataSource={fieldNames}
                        label={t('FIELD_NAME')}
                        isRequired={columnName !== null}
                        onValueChanged={(field, value) => {
                            setFieldName(value.value);
                            props.onChange(columnName!, value.value);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default ColumnToFieldMapper;