import { FunctionComponent, useState } from 'react';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import { Col, Row } from 'react-bootstrap';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import EmailTemplate from '@business/mailing/EmailTemplate';
import EmailMessage from '@business/mailing/EmailMessage';

interface EmailMessageEditorProps
{
    emailMessage?: EmailMessage;
    onEmailMessageChanged: (emailMessage: EmailMessage) => void;
}

const EmailMessageEditor: FunctionComponent<EmailMessageEditorProps> = (props) =>
{
    const t = useTranslate();
    const [emailMessage, setEmailMessage, updateEmailMessageField] = useUpdateEntityFields<EmailMessage>(props.emailMessage ?? new EmailMessage());
    const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

    /** Load data */
    useLoad(async () => {
        // load email templates
        let emailTemplateResult = await EmailTemplate.manager.load();
        if (emailTemplateResult) setEmailTemplates(emailTemplateResult.entities);
    });

    /** Handle value changed */
    const onValueChanged = (field: string, value: any) =>
    {
        updateEmailMessageField(field, value);
        emailMessage[field] = value; // hack: update the email message before sending it back to the parent
        props.onEmailMessageChanged(emailMessage);
    }
    
    /** Render */
    return (
        <>
             <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="emailTemplate"
                        label={t('EMAIL_TEMPLATE')}
                        isRequired={true}
                        value={emailMessage?.emailTemplate}
                        dataSource={emailTemplates}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="name"
                        onValueChanged={(field, value) => onValueChanged(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="subject"
                        label={t('SUBJECT')}
                        isRequired={true}
                        value={emailMessage?.subject}
                        onValueChanged={(field, value) => onValueChanged(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="preheader"
                        label={t('PREHEADER')}
                        value={emailMessage?.preheader}
                        onValueChanged={(field, value) => onValueChanged(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.RichTextEditor}
                        field="body"
                        label={t('BODY')}
                        isRequired={true}
                        value={emailMessage?.body}
                        onValueChanged={(field, value) => onValueChanged(field, value)}
                    />
                </Col>
            </Row>
        </>
    );
}

export default EmailMessageEditor;