import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import OptInForm from '@business/forms/OptInForm';
import OptInFormFieldView from './OptInFormFieldView';
import ContactGroup from '@business/contacts/ContactGroup';
import ContactGroupsEditor from '@components/contacts/ContactGroupsEditor';
import { OptInFormTheme } from '@business/forms/generated/OptInForm.generated';

interface OptInFormAddEditFormProps extends EntityAddEditFormProps<OptInForm>
{

}

const OptInFormAddEditForm: FunctionComponent<OptInFormAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<OptInForm>(props.entity ?? new OptInForm());
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);

    /** Load additional data */
    useLoad(async () =>
    {
        // load existing contact groups
        let contactGroupResult = await entity?.contactGroups.load();
        if (contactGroupResult) setContactGroups(contactGroupResult.entities);
    });

    const onSave = async () =>
    {
        // save entity
        let success = await entity.save();

        if (success)
        {
            // set contact groups
            await entity.contactGroups.set(contactGroups);
        }

        return success;
    }

    /** Tab: general */
    let cmpTabGeneral =
        <>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="description"
                        label={t('DESCRIPTION')}
                        value={entity?.description}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="markAsConfirmed"
                        label={t('MARK_AS_CONFIRMED')}
                        value={entity?.markAsConfirmed}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="isPopup"
                        label={t('IS_POPUP')}
                        value={entity?.isPopup}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="popupDelay"
                        label={t('POPUP_DELAY')}
                        isVisible={entity?.isPopup}
                        value={entity?.popupDelay}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
        ;

    /** Tab: fields */
    let cmpTabFields =
        <>
            <OptInFormFieldView optInForm={entity} />
        </>
    ;

    /** Tab: layout */
    let cmpTabLayout =
        <>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="theme"
                        label={t('THEME')}
                        isRequired={true}
                        dataSource={enumToDataSource(OptInFormTheme, t)}
                        value={entity?.theme}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="headerTitle"
                        label={t('HEADER_TITLE')}
                        value={entity?.headerTitle}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="headerSubtitle"
                        label={t('HEADER_SUBTITLE')}
                        value={entity?.headerSubtitle}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.RichTextEditor}
                        field="bodyText"
                        label={t('BODY_TEXT')}
                        value={entity?.bodyText}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="submitButtonCta"
                        label={t('SUBMIT_BUTTON_CTA')}
                        value={entity?.submitButtonCta}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="submitButtonText"
                        label={t('SUBMIT_BUTTON_TEXT')}
                        value={entity?.submitButtonText}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="declineText"
                        label={t('DECLINE_TEXT')}
                        value={entity?.declineText}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.RichTextEditor}
                        field="footerText"
                        label={t('FOOTER_TEXT')}
                        value={entity?.footerText}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="thankYouTitle"
                        label={t('THANK_YOU_TITLE')}
                        value={entity?.thankYouTitle}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.RichTextEditor}
                        field="thankYouText"
                        label={t('THANK_YOU_TEXT')}
                        value={entity?.thankYouText}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
        ;

    /** Tab: contact groups */
    let cmpTabContactGroups =
        <>
            <Row>
                <Col>
                    <ContactGroupsEditor
                        selectedContactGroups={contactGroups}
                        onChange={(contactGroups) => setContactGroups(contactGroups)}
                    />
                </Col>
            </Row>
        </>
        ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<OptInForm>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('OPT_IN_FORM').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.name}
                onSave={() => onSave()}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {
                            eventKey: 'layout',
                            title: t('ADD_EDIT_TAB_LAYOUT'),
                            content: cmpTabLayout,
                        },
                        {
                            eventKey: 'fields',
                            title: t('ADD_EDIT_TAB_FIELDS'),
                            content: cmpTabFields,
                        },
                        {
                            eventKey: 'contactGroups',
                            title: t('ADD_EDIT_TAB_CONTACT_GROUPS'),
                            content: cmpTabContactGroups,
                        },
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default OptInFormAddEditForm;