import React, { useRef } from 'react';
import { FormEvent, FunctionComponent, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useSetStateFromProp, useTranslate } from '@xFrame4/components/Hooks';
import { tl } from '@xFrame4/common/Utils';
import { store } from '@redux/store';
import EntityPageTitle from './EntityPageTitle';

export interface AddEditFormProps
{
    mode?: AddEditFormMode;
    /** The title for the form. */
    title?: string;
    /** The title for the add mode. */
    titleAdd?: string;
    /** The title for the edit mode. */
    titleEdit?: string;
    /** The label for the save button. */
    saveButtonLabel?: string;
    /** The label for the cancel button. */
    cancelButtonLabel?: string;
    /** Child JSX element or elements. */
    children?: JSX.Element | JSX.Element[];
    /** An error message to display. */
    errorMessage?: string | null;
    /** The size of the Modal form. */
    modalSize?: AddEditFormModalSize;
    /** The position of the Modal form. */
    modalPosition?: AddEditFormModalPosition;
    /** Validates the data before saving. */
    onValidate?: () => boolean;
    /** Handle the save. */
    onSave?: () => Promise<boolean>;
    /** Called after the save has been succesfuly handled. */
    afterSave?: () => void;
    /** Closing the form (saved or not) */
    onClose?: () => void;
}

const AddEditForm: FunctionComponent<AddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const formRef = useRef<HTMLFormElement>(null);
    const [validated, setValidated] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(props.errorMessage ?? null);
    useSetStateFromProp(setErrorMessage, props.errorMessage ?? null);

    /** Save the form data. */
    const save = async () =>
    {
        let success = false;

        setIsSaving(true);
        try
        {
            if (props.onSave) success = await props.onSave();
            setIsSaving(false);

            if (props.afterSave && success) props.afterSave();
        }
        catch (error)
        {
            console.log(error);

            if (error instanceof Error) 
            {
                setErrorMessage(error.message);
            }
            else if (typeof error === 'string') 
            {
                setErrorMessage(error);
            }
            else
            {
                setErrorMessage(t('ERROR'));
            }

            setIsSaving(false);
        }

        if (success) close();
    };

    /** Close the form. */
    const close = () =>
    {
        props.onClose?.();
    };

    /** Form submit */
    const onSubmit = () =>
    {
        if (formRef.current == null) return;

        // Validate
        if (formRef.current.checkValidity() === true) // Standard validation
        {
            let isValid = true;
            if (props.onValidate !== undefined) isValid = props.onValidate(); // Additional custom validation

            // Form is valid: save
            if (isValid) save();
        }

        setValidated(true);
    };

    /** Handle the enter key */
    const handleKeyDown = (event: React.KeyboardEvent) => 
    {
        if (event.key === 'Enter' && !event.shiftKey) 
        {
            event.preventDefault();
            onSubmit();
        }
    };
    

    /** Title */
    let title = '';
    if (props.title !== undefined)
    {
        title = props.title;
    }
    else
    {
        if (props.mode == AddEditFormMode.Add && props.titleAdd)
        {
            title = props.titleAdd;
        }
        else if (props.mode == AddEditFormMode.Edit && props.titleEdit)
        {
            title = props.titleEdit
        }
    }

    /** Render */
    return (
        <Modal
            show={true}
            onHide={() => close()}
            backdrop="static"
            dialogClassName={
                (props.modalSize ?? AddEditFormModalSize.FullWidth) 
                + ' ' +
                (props.modalPosition ?? AddEditFormModalPosition.Auto)
            }
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="h4 px-3">{title}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="add-edit-form px-3 pb-4">
                    <Form 
                        ref={formRef} 
                        noValidate 
                        validated={validated} 
                        onSubmit={() => false} 
                        onKeyDown={(event) => handleKeyDown(event)}
                        className="add-edit-form"
                    >
                        {props.children}

                        {errorMessage != null &&
                            <div className="add-edit-error-message mt-3">
                                <div role="alert" className="fade text-danger alert alert-danger show">{errorMessage}</div>
                            </div>
                        }

                        <div className="d-flex justify-content-end mt-3">
                            <Button
                                variant="secondary"
                                onClick={() => close()}
                                disabled={isSaving}
                                className="me-3"
                            >
                                {props.cancelButtonLabel ?? t('CANCEL')}
                            </Button>

                            <Button
                                variant="primary"
                                onClick={() => onSubmit()}
                                disabled={isSaving}
                            >
                                {props.saveButtonLabel ?? t('SAVE')}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
}

AddEditForm.defaultProps = {
    titleAdd: tl('ADD', store.getState().layout.language.code),
    titleEdit: tl('EDIT', store.getState().layout.language.code),
}

export enum AddEditFormMode
{
    Add = 'add',
    Edit = 'edit'
}

export enum AddEditFormModalSize
{
    Small = 'modal-sm',
    Medium = 'modal-md',
    Large = 'modal-lg',
    FullWidth = 'modal-full-width'
}

export enum AddEditFormModalPosition
{
    Auto = '',
    Center = 'modal-dialog-centered',
    Top = 'modal-top',
    Bottom = 'modal-bottom',
    Right = 'modal-right',
}

export default AddEditForm;