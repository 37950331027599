import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import SmtpSetup from '@business/mailing/SmtpSetup';
import { SmtpSetupEncryption } from '@business/mailing/generated/SmtpSetup.generated';

interface SmtpSetupAddEditFormProps extends EntityAddEditFormProps<SmtpSetup>
{

}

const SmtpSetupAddEditForm: FunctionComponent<SmtpSetupAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<SmtpSetup>(props.entity ?? new SmtpSetup());

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="isDefault"
                        label={t('IS_DEFAULT')}
                        value={entity?.isDefault}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="host"
                        label={t('HOST')}
                        isRequired={true}
                        value={entity?.host}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="port"
                        label={t('PORT')}
                        isRequired={true}
                        value={entity?.port}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="username"
                        label={t('USERNAME')}
                        isRequired={true}
                        value={entity?.username}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Password}
                        field="password"
                        label={t('PASSWORD')}
                        isRequired={true}
                        value={entity?.password}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="encryption"
                        label={t('ENCRYPTION')}
                        isRequired={true}
                        dataSource={enumToDataSource(SmtpSetupEncryption, t)}
                        value={entity?.encryption}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor 
                        type={FieldEditorType.Text}
                        field="fromAddress"
                        label={t('FROM_ADDRESS')}
                        value={entity?.fromAddress}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor 
                        type={FieldEditorType.Text}
                        field="fromName"
                        label={t('FROM_NAME')}
                        value={entity?.fromName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<SmtpSetup>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('SMTP_SETUP').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.name}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default SmtpSetupAddEditForm;