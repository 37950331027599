import { gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import CompanyGenerated, { CompanyGeneratedEntityManager } from './generated/Company.generated';

export class CompanyEntityManager extends CompanyGeneratedEntityManager<Company>
{
    constructor()
    {
        super({
            createEntity: () => new Company(),
            fields: [
                { name: 'logoImage', type: BusinessEntityFieldType.File, isInFieldDetails: false, inputProperty: 'logoImage' },
                { name: 'images', type: BusinessEntityFieldType.File, isInFieldDetails: false, inputProperty: 'images' }
            ]
        });
    }
}

export class Company extends CompanyGenerated
{
    static manager: CompanyEntityManager = new CompanyEntityManager();

    /**
     * Get the company entity. There can be only one company entity.
     */
    static async theCompany()
    {
        // Get the secret URL via the API
        let queryText = `
        query theCompany {
            theCompany {
              ...CompanyDetailsFragment
            }
          } 
          ${this.manager.buildEntityDetailsFragment()}                 
        `

        let { data } = await client.query({
            query: gql(queryText)
        });

        if (data.theCompany != null)
        {// company object already created
            return Company.createFromGraphQL(data.theCompany) as Company;
        }
        else
        {// company object not created yet
            return new Company();
        }
    }
}

export default Company;
