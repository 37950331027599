import AutomatedJourneyGenerated, { AutomatedJourneyGeneratedEntityManager } from './generated/AutomatedJourney.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class AutomatedJourneyEntityManager extends AutomatedJourneyGeneratedEntityManager<AutomatedJourney>
{
    constructor()
    {
        super({
            createEntity: () => new AutomatedJourney(),
        });
    }
}

export class AutomatedJourney extends AutomatedJourneyGenerated
{
    static _manager: AutomatedJourneyEntityManager;
    static get manager(): AutomatedJourneyEntityManager
    {
        if (!this._manager) this._manager = new AutomatedJourneyEntityManager();
        return this._manager;
    }
}

export default AutomatedJourney;