import { FunctionComponent, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslate } from '@xFrame4/components/Hooks';

interface ConfirmDialogProps
{
    type: ConfirmDialogType,
    title: string,
    message?: string,
    isOpen: boolean,
    onClose: (result: ConfirmDialogResult) => void;
}

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = (props) =>
{
    const t = useTranslate();

    /** Close the dialog, send back the result. */
    const onClose = (result: ConfirmDialogResult) =>
    {
        props.onClose(result);
    };

    /** Render */
    return (
        <Dialog
            open={props.isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {[ConfirmDialogType.OkCancel, ConfirmDialogType.OK].indexOf(props.type) != -1 && <Button onClick={() => onClose(ConfirmDialogResult.OK)} autoFocus>{t('OK')}</Button>}
                {[ConfirmDialogType.OkCancel].indexOf(props.type) != -1 && <Button onClick={() => onClose(ConfirmDialogResult.Cancel)} autoFocus>{t('CANCEL')}</Button>}
                {[ConfirmDialogType.YesNo].indexOf(props.type) != -1 && <Button onClick={() => onClose(ConfirmDialogResult.Yes)} autoFocus>{t('YES')}</Button>}
                {[ConfirmDialogType.YesNo].indexOf(props.type) != -1 && <Button onClick={() => onClose(ConfirmDialogResult.No)} autoFocus>{t('NO')}</Button>}
            </DialogActions>
        </Dialog>
    );
}

export enum ConfirmDialogType
{
    OkCancel,
    YesNo,
    OK
}

export enum ConfirmDialogResult
{
    OK,
    Cancel,
    Yes,
    No
}

export default ConfirmDialog;