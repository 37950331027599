import { gql } from '@apollo/client';
import EmailTemplate from './EmailTemplate';
import EmailMessageGenerated, { EmailMessageGeneratedEntityManager } from './generated/EmailMessage.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import client from '@graphql/apollo-client';

export class EmailMessageEntityManager extends EmailMessageGeneratedEntityManager<EmailMessage>
{
    constructor()
    {
        super({
            createEntity: () => new EmailMessage(),
        });
    }
}

export class EmailMessage extends EmailMessageGenerated
{
    static _manager: EmailMessageEntityManager;
    static get manager(): EmailMessageEntityManager
    {
        if (!this._manager) this._manager = new EmailMessageEntityManager();
        return this._manager;
    }

    /**
     * Create an email message from a template. The entity will be saved to the database.
     */
    static async createFromTemplate(emailTemplate: EmailTemplate)
    {
        let query = `
            mutation CreateEmailMessageFromTemplate($emailTemplateId: Int!) {
                createEmailMessageFromTemplate(emailTemplateId: $emailTemplateId) {
                    ...${EmailMessage.manager.name}DetailsFragment
                }
            }

            ${EmailMessage.manager.buildEntityDetailsFragment()}
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                emailTemplateId: emailTemplate.id,
            }
        });

        if (result.data.createEmailMessageFromTemplate != null)
        {
            let emailMessage = EmailMessage.createFromGraphQL(result.data.createEmailMessageFromTemplate) as EmailMessage;
            return emailMessage;
        }
        else
        {
            return null;
        }
    }
}

export default EmailMessage;