import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@xFrame4/components/admin/EntityView';
import { enumToDataSource, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import EmailCampaign from '@business/mailing/EmailCampaign';
import { EmailCampaignStatus } from '@business/mailing/generated/EmailCampaign.generated';
import EmailCampaignWizard from './EmailCampaignWizard';
import BulkMessageStatsForm from './BulkMessageStatsForm';
import SplitTestMessagesForm from './SplitTestMessagesForm';

interface EmailCampaignViewProps
{

}

const EmailCampaignView: FunctionComponent<EmailCampaignViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<EmailCampaign>(EmailCampaign);
    const [bulkMessageStatsEntity, setBulkMessageStatsEntity] = useState<EmailCampaign>();
    const [entityWithSplitTestMessages, setEntityWithSplitTestMessages] = useState<EmailCampaign>();

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<EmailCampaign>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
            { field: 'status', headerName: t('STATUS'), type: 'string', valueGetter: (params) => t(params.value as string) },
            { field: 'sendDate', headerName: t('SEND_DATE'), type: 'dateTime', width: 200 },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
                <EmailCampaignWizard
                    emailCampaign={addEditEntity}
                    afterFinish={(mode, entity) => onAddEditClose(mode, entity)}
                />
            }

            {bulkMessageStatsEntity &&
                <BulkMessageStatsForm
                    title={t('BULK_MESSAGE_STATS') + ' - ' + bulkMessageStatsEntity.name}
                    emailCampaign={bulkMessageStatsEntity}
                    onClose={() => setBulkMessageStatsEntity(undefined)}
                />
            }

            {entityWithSplitTestMessages &&
                <SplitTestMessagesForm
                    emailCampain={entityWithSplitTestMessages}
                    onClose={() => setEntityWithSplitTestMessages(undefined)}
                />
            }

            <EntityView<EmailCampaign>
                id="emailCampaigns"
                isVisible={addEditEntity === undefined && bulkMessageStatsEntity === undefined}
                title={t('EMAIL_CAMPAIGNS')}
                manager={EmailCampaign.manager}
                baseFilter={{}}
                pageSize={10}
                buttonsLeft={[
                    {
                        text: t('CREATE_EMAIL_CAMPAIGN'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    { field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text },
                    { field: 'status_In', label: t('STATUS_IN'), type: FieldEditorType.List, dataSource: enumToDataSource(EmailCampaignStatus, t) },
                    { field: 'sendDate_Gte', label: t('SEND_DATE_GTE'), type: FieldEditorType.Date },
                    { field: 'sendDate_Lte', label: t('SEND_DATE_LTE'), type: FieldEditorType.Date }
                ]}
                actions={[
                    { label: t('STATS'), icon: <QueryStatsIcon />, onClick: (entity) => setBulkMessageStatsEntity(entity) },
                    { label: t('SPLIT_TEST_MESSAGES'), icon: <CallSplitIcon />, onClick: (entity) => setEntityWithSplitTestMessages(entity), isHidden: (entity) => entity.isSplitTest == false }
                ]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default EmailCampaignView;