import { gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import OptInFormFieldGenerated, { OptInFormFieldGeneratedEntityManager, OptInFormFieldSource, OptInFormFieldType } from './generated/OptInFormField.generated';

export type OptInFormContactField = {
    name: string;
    type: OptInFormFieldType;
    souce: OptInFormFieldSource;
};

export class OptInFormFieldEntityManager extends OptInFormFieldGeneratedEntityManager<OptInFormField>
{
    constructor()
    {
        super({
            createEntity: () => new OptInFormField(),
        });
    }
}

export class OptInFormField extends OptInFormFieldGenerated
{
    static manager: OptInFormFieldEntityManager = new OptInFormFieldEntityManager();

    static async listAvailableContactFields()
    {
        let query = `
            query ListAvailableContactFields {
                listAvailableContactFields {
                name
                type
                source
                }
            }
        `;
        
        let result = await client.query({
            query: gql(query),
        });
        
        if (result.data && result.data.listAvailableContactFields)
        {
            return result.data.listAvailableContactFields as OptInFormContactField[];
        }
        else
        {
            return [];
        }
    }

    /**
     * Create an array from a text string containing the list options, one per line.
     * 
     * @param listOptionsText A text string containing the list options, one per line.
     */
    static parseListOptions(listOptionsText: string)
    {
        return listOptionsText.split('\n').map((option) => option.trim());
    }

    /**
     * Create a text string from an array of list options, one per line.
     * 
     * @param listOptions An array of list options.
     */
    static listOptionsToText(listOptions: string[])
    {
        if (listOptions == null) return '';
        
        return listOptions.join('\n');
    }
}

export default OptInFormField;