import { gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import CustomField, { CustomFieldValue } from '@business/fields/CustomField';
import { CustomFieldType } from '@business/fields/generated/CustomField.generated';
import BusinessEntity from '@xFrame4/business/base/BusinessEntity';

export class CustomFieldValueManager
{
    /** The entity which owns this custom field value. */
    entity: BusinessEntity;
    entityClassName: string;
    
    constructor(entity: BusinessEntity, entityClassName: string)
    {
        this.entity = entity;
        this.entityClassName = entityClassName;
    }

    /**
     * Get the custom fields for this entity.
     */
    async getCustomFields()
    {
        let query = `
            query GetCustomFieldsFor${this.entityClassName}($entityId: Int!, $entityClassName: String!) {
                getCustomFieldsFor${this.entityClassName}(entityId: $entityId, entityClassName: $entityClassName) {
                    ...${CustomField.manager.name}DetailsFragment
                }
            }
            ${CustomField.manager.buildEntityDetailsFragment()}
        `;

        let result = await client.query({
            query: gql(query),
            variables: {
                entityId: this.entity.id,
                entityClassName: this.entityClassName,
            }
        });

        return result.data[`getCustomFieldsFor${this.entityClassName}`] as CustomField[];
    }

    /**
     * Get a custom field value for this entity.
     */
    async getCustomFieldValue(customField: CustomField)
    {
        let query = `
            query GetCustomFieldValueFor${this.entityClassName}($customFieldId: Int!, $entityId: Int!, $entityClassName: String!) {
                getCustomFieldValueFor${this.entityClassName}(customFieldId: $customFieldId, entityId: $entityId, entityClassName: $entityClassName)
            }
        `;
        
        let result = await client.query({
            query: gql(query),
            variables: {
                customFieldId: customField.id,
                entityId: this.entity.id,
                entityClassName: this.entityClassName
            }
        });

        let value = result.data[`getCustomFieldValueFor${this.entityClassName}`];

        return this.parseCustomFieldValueFromString(customField, value);
    }

    async setCustomFieldValue(customField: CustomField, value: any)
    {
        let query = `
            mutation SetCustomFieldValueFor${this.entityClassName}($customFieldId: Int!, $entityId: Int!, $entityClassName: String!, $value: String) {
                setCustomFieldValueFor${this.entityClassName}(customFieldId: $customFieldId, entityId: $entityId, entityClassName: $entityClassName, value: $value) {
                    success
                    message
                }
            }
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                customFieldId: customField.id,
                entityId: this.entity.id,
                entityClassName: this.entityClassName,
                value: value != null ? value.toString() : null,
            }
        });

        return result.data[`setCustomFieldValueFor${this.entityClassName}`].success as boolean;
    }

    /**
     * Delete a custom field value for the related entity.
     * 
     * @param customField The custom field.
     */
    async deleteCustomFieldValue(customField: CustomField)
    {
        let query = `
            mutation DeleteCustomFieldValueFor${this.entityClassName}($customFieldId: Int!, $entityId: Int!, $entityClassName: String!) {
                deleteCustomFieldValueFor${this.entityClassName}(customFieldId: $customFieldId, entityId: $entityId, entityClassName: $entityClassName) {
                    success
                    message
                }
            }
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                customFieldId: customField.id,
                entityId: this.entity.id,
                entityClassName: this.entityClassName,
            }
        });

        return result.data[`deleteCustomFieldValueFor${this.entityClassName}`].success as boolean;
    }

    /**
     * Parse a custom field value from a string representation.
     */
    parseCustomFieldValueFromString(customField: CustomField, value: string)
    {
        if (customField.type === CustomFieldType.BOOLEAN)
        {
            return value === 'true';
        }
        else if (customField.type === CustomFieldType.NUMBER)
        {
            return parseFloat(value);
        }
        else
        {
            return value;
        }
    }

    /**
     * Load the values for the specified custom fields.
     * 
     * @param customFields The custom fields.
     */
    async getValuesForCustomFields(customFields: CustomField[], loadValue: boolean = false)
    {
        let cfvs: CustomFieldValue[] = [];

        for (let customField of customFields)
        {
            let value: any = null;
            if (loadValue) value = await this.getCustomFieldValue(customField);
            
            let customFieldValue: CustomFieldValue = {
                customField: customField,
                value: value
            };

            cfvs.push(customFieldValue);
        }

        return cfvs;
    }
}

export default CustomFieldValueManager;