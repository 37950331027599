import React from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@xFrame4/components/admin/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import ContactGroup from '@business/contacts/ContactGroup';
import ContactGroupAddEditForm from './ContactGroupAddEditForm';

interface ContactGroupViewProps
{

}

const ContactGroupView: FunctionComponent<ContactGroupViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<ContactGroup>(ContactGroup);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<ContactGroup>>(
        () => [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <ContactGroupAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<ContactGroup>
                id="contacts"
                title={t('CONTACT_GROUPS')}
                manager={ContactGroup.manager}
                baseFilter={{}}
                pageSize={10}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text},
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default ContactGroupView;