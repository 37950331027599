import React, { FunctionComponent } from 'react';
import { Card } from 'react-bootstrap';
import
    {
        Chart as ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ChartData,
        ChartOptions,
    } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface LineChartProps
{
    title: string;
    label: string
    labels: string[];
    data: number[];
    color?: string;
}

function colorHexToRgba(color: string, alpha?: number): string
{
    let hex = color.replace('#', '');

    if (hex.length === 3)
    {
        hex += hex;
    }

    let r = parseInt(hex.substr(0, 2), 16);
    let g = parseInt(hex.substr(2, 2), 16);
    let b = parseInt(hex.substr(4, 2), 16);

    if (alpha)
    {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgba(${r}, ${g}, ${b})`;
}

const LineChart: FunctionComponent<LineChartProps> = (props) =>
{
    // chart data
    const lineChartData: ChartData<'line'> = {
        labels: props.labels,
        datasets: [
            {
                label: props.label,
                backgroundColor: colorHexToRgba(props.color ?? '536de6'),
                borderColor: '#' + (props.color ?? '727cf5'),
                data: props.data,
                tension: 0.4,
                fill: {
                    target: 'origin',
                    above: colorHexToRgba(props.color ?? '536de6'),
                },
                capBezierPoints: true,
            }
        ],
    };

    // default options
    const lineChartOpts: ChartOptions<'line'> = {
        maintainAspectRatio: false,
        plugins: {
            filler: {
                propagate: false,
            },
            tooltip: {
                intersect: false,
            },
            legend: {
                display: false,
            },
        },
        hover: {
            intersect: true,
        },
        scales: {
            x: {
                grid: {
                    color: 'rgba(0,0,0,0.05)',
                },
            },
            y: {
                ticks: {
                    stepSize: 20,
                },
                display: true,
                grid: {
                    color: 'rgba(0,0,0,0)',
                },
            },
        },
    };

    return (
        <Card>
            <Card.Body>
                <h4 className="header-title mb-3">{props.title}</h4>
                <div style={{ height: '320px' }} className="mt-3 chartjs-chart">
                    <Line data={lineChartData} options={lineChartOpts} />
                </div>
            </Card.Body>
        </Card>
    );
};

export default LineChart;