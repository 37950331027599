import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { Card, Row, Col } from 'react-bootstrap';

interface SimpleStatWidgetProps
{
    title: string;
    value: string;
    bottomValue?: string;
    bottomText?: string;
    mainTextClass?: string;
    backgroundClass?: string;
    bottomValueClass?: string;
    bottomTextClass?: string;
    bottomValueIcon?: string;
}

const SimpleStatWidget: FunctionComponent<SimpleStatWidgetProps> = (props) =>
{
    /** Render */
    return (
        <Card className={classNames('widget-flat', props.backgroundClass)}>
            <Card.Body>
                <Row className="align-items-center">
                    <Col className="col-6">
                        <h5
                            className={classNames('fw-normal', 'mt-0', 'text-truncate', props.mainTextClass ? props.mainTextClass : 'text-muted')}
                            title={props.title}
                        >
                            {props.title}
                        </h5>
                        <h3 className={classNames('my-2', 'py-1', props.mainTextClass ? props.mainTextClass : 'text-muted')}>{props.value}</h3>

                        {props.bottomValue !== undefined && (
                            <p className={classNames('mb-0', props.bottomValueClass ? props.bottomValueClass : 'text-muted')}>
                                <span className={classNames(props.bottomValueClass ? props.bottomValueClass : 'text-info', 'me-2')}>
                                    {props.bottomValueIcon && <i className={props.bottomValueIcon}></i>}
                                    {props.bottomValue}
                                </span>
                                <span className={classNames(props.bottomTextClass ? props.bottomTextClass : 'text-muted')}>
                                    {props.bottomText}
                                </span>
                            </p>
                        )}
                    </Col>

                    <Col className="col-6">
                        
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default SimpleStatWidget;