import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { numberFormat } from '@xFrame4/common/Functions';
import { t } from '@xFrame4/common/Utils';
import EmailMessage from '@business/mailing/EmailMessage';
import { EmailMessageStat, MessageStatsCalculator } from '@business/mailing/BulkMessageStatsManager';
import SimpleStatWidget from './SimpleStatWidget';

interface EmailMessageStatsDashboardProps
{
    emailMessage: EmailMessage;
    emailMessageStat: EmailMessageStat;
}

/**
 * Component: holds a list of widgets for displaying email message statistics.
 */
const EmailMessageStatsDashboard: FunctionComponent<EmailMessageStatsDashboardProps> = (props) =>
{
    let messageStatsCalculator = new MessageStatsCalculator(props.emailMessageStat.stats);

    /** Render */
    return (
        <div className="mb-4">
            <Row>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_RECIPIENT_COUNT')}
                        value={numberFormat(messageStatsCalculator.stats.recipientCount, 0, '.', ',')}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_PENDING_COUNT')}
                        value={numberFormat(messageStatsCalculator.stats.pendingCount, 0, '.', ',')}
                        bottomValue={numberFormat(messageStatsCalculator.pendingRate, 2, '.', ',') + '%'}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_DELIVERED_COUNT')}
                        value={numberFormat(messageStatsCalculator.stats.deliveredCount, 0, '.', ',')}
                        bottomValue={numberFormat(messageStatsCalculator.sendingProgress, 2, '.', ',') + '%'}
                        mainTextClass="text-white"
                        bottomValueClass='text-white'
                        backgroundClass="bg-success"
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_FAILED_COUNT')}
                        value={numberFormat(messageStatsCalculator.stats.failedCount, 0, '.', ',')}
                        bottomValue={numberFormat(messageStatsCalculator.failedRate, 2, '.', ',') + '%'}
                        mainTextClass="text-white"
                        bottomValueClass='text-white'
                        backgroundClass="bg-danger"
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_OPEN_COUNT')}
                        value={numberFormat(messageStatsCalculator.stats.openCount, 0, '.', ',')}
                        bottomValue={numberFormat(messageStatsCalculator.openRate, 2, '.', ',') + '%'}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_CLICK_COUNT')}
                        value={numberFormat(messageStatsCalculator.stats.clickCount, 0, '.', ',')}
                        bottomValue={numberFormat(messageStatsCalculator.clickRate, 2, '.', ',') + '%'}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_UNSUBSCRIBE_COUNT')}
                        value={numberFormat(messageStatsCalculator.stats.unsubscribeCount, 0, '.', ',')}
                        bottomValue={numberFormat(messageStatsCalculator.unsubscribeRate, 2, '.', ',') + '%'}
                    />
                </Col>
                <Col sm={6} xxl={3}>
                    <SimpleStatWidget
                        title={t('BULK_MESSAGE_STAT_BOUNCED_COUNT')}
                        value={numberFormat(messageStatsCalculator.stats.bouncedCount, 0, '.', ',')}
                        bottomValue={numberFormat(messageStatsCalculator.bounceRate, 2, '.', ',') + '%'}
                        mainTextClass="text-white"
                        bottomValueClass='text-white'
                        backgroundClass="bg-warning"
                    />
                </Col>
            </Row>
        </div>
    );
}

export default EmailMessageStatsDashboard;