import SegmentCondition from '@business/contacts/SegmentCondition';
import { useTranslate } from '@xFrame4/components/Hooks';
import AddEditForm, { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import { FunctionComponent, useState } from 'react';
import SegmentConditionEditorContainer from './SegmentConditionEditorContainer';
import Contact from '@business/contacts/Contact';
import { Row, Col, Spinner } from 'react-bootstrap';

interface ExportContactsFormProps
{
    onClose: () => void;
}

const ExportContactsForm: FunctionComponent<ExportContactsFormProps> = (props) =>
{
    const t = useTranslate();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [segmentConditions, setSegmentConditions] = useState<SegmentCondition[]>([]);

    const onValidate = () : boolean =>
    {
        return true;
    }

    const onExport = async () =>
    {
        setIsProcessing(true);

        let { success, downloadLink } = await Contact.exportContactsToExcel(segmentConditions);
        if (success)
        {
            window.open(downloadLink, '_blank');
        }

        setIsProcessing(false);

        return success;
    }

    /** Tab: general */
    let cmpTabGeneral =
        <>
            <SegmentConditionEditorContainer 
                segmentConditions={segmentConditions}
                onChange={(segmentConditions) => setSegmentConditions(segmentConditions)}
            />
            { isProcessing &&
                <Row className="my-2">
                    <Col className="text-center">
                        <Spinner className="text-primary" />
                    </Col>
                </Row>
            }
        </>;
    
    /** Render */
    return (
        <>
            <AddEditForm 
                mode={AddEditFormMode.Add}
                title={t('EXPORT_CONTACTS')}
                saveButtonLabel={t('EXPORT')}
                errorMessage={errorMessage}
                onValidate={onValidate}
                onSave={() => onExport()}
                onClose={props.onClose}
            >
            <TabContainer
                tabs={[
                    {
                        eventKey: 'general',
                        title: t('ADD_EDIT_TAB_GENERAL'),
                        content: cmpTabGeneral,
                    }
                ]}
            />
            </AddEditForm>
        </>
    );
}

export default ExportContactsForm;