import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import SmtpSetup from '@business/mailing/SmtpSetup';
import User from '@xFrame4/business/users/User';
import ContactGroup from '@business/contacts/ContactGroup';
import Segment from '@business/contacts/Segment';

export enum AutomatedJourneyStatus
{
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',

}

export class AutomatedJourneyGeneratedEntityManager<B extends AutomatedJourneyGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'AutomatedJourney',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'status', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'smtpSetup', type: BusinessEntityFieldType.ForeignKey, inputName: 'smtpSetupId', inputProperty: 'smtpSetup.id', relatedManager: SmtpSetup.manager },
				{ name: 'user', type: BusinessEntityFieldType.ForeignKey, inputName: 'userId', inputProperty: 'user.id', relatedManager: User.manager },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'automatedJourneys',
        });
    }

}
export class AutomatedJourneyGenerated extends BusinessEntity
{
	name!: string;
	status: AutomatedJourneyStatus = AutomatedJourneyStatus.ENABLED;
	smtpSetup: SmtpSetup | null = null;
	user!: User;

	contactGroups: ManyToManyCollection<ContactGroup> = new ManyToManyCollection<ContactGroup>(this, 'business/mailing/AutomatedJourney', 'contact_groups', 'business/contacts/ContactGroup', ContactGroup.manager, 'automatedJourney');
	segments: ManyToManyCollection<Segment> = new ManyToManyCollection<Segment>(this, 'business/mailing/AutomatedJourney', 'segments', 'business/contacts/Segment', Segment.manager, 'automatedJourney');
}

export default AutomatedJourneyGenerated;