import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import Segment from '@business/contacts/Segment';

export enum SegmentConditionOperator
{
    EXACT = 'EXACT',
    ICONTAINS = 'ICONTAINS',
    LTE = 'LTE',
    GTE = 'GTE',
    IN = 'IN',

}

export class SegmentConditionGeneratedEntityManager<B extends SegmentConditionGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'SegmentCondition',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'segment', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'segmentId', inputProperty: 'segment.id', relatedManager: Segment.manager },
				{ name: 'fieldName', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'operator', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'value', type: BusinessEntityFieldType.Text, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'segmentConditions',
        });
    }

}
export class SegmentConditionGenerated extends BusinessEntity
{
	segment!: Segment;
	fieldName!: string;
	operator!: SegmentConditionOperator;
	value!: string;

}

export default SegmentConditionGenerated;