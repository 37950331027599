import { FunctionComponent, useState } from 'react';
import { ColumnNamesAndFieldNamesForMapping } from '@business/contacts/Contact';
import ColumnToFieldMapper from './ColumnToFieldMapper';

interface ImportMapperProps
{
    columnNames: string[];
    fieldNames: string[];
    onMappingChanged: (columnNameToFieldNameMap: { [key: string]: string }) => void;
}

/**
 * Component: map Excel columns to contact fields or custom contact fields.
 */
const ImportMapper: FunctionComponent<ImportMapperProps> = (props) =>
{
    const [columnNameToFieldNameMap, setColumnNameToFieldNameMap] = useState<{ [key: string]: string }> ({});

    let cmpColumnToFieldMappers = props.columnNames.map((columnName, index) =>
        <ColumnToFieldMapper
            key={index}
            columnNames={props.columnNames}
            fieldNames={props.fieldNames}
            onChange={(columnName, fieldName) => {
                columnNameToFieldNameMap[columnName] = fieldName;
                setColumnNameToFieldNameMap(columnNameToFieldNameMap);
                props.onMappingChanged(columnNameToFieldNameMap);
            }}
        />
    );
    
    /** Render */
    return (
        <>
            {cmpColumnToFieldMappers}
        </>
    );
}

export default ImportMapper;