import { gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import ContactGenerated, { ContactGeneratedEntityManager } from './generated/Contact.generated';
import SegmentCondition from './SegmentCondition';
import CustomFieldValueManager from './CustomFieldValueManager';
import ContactGroup from './ContactGroup';

export type ColumnNamesAndFieldNamesForMapping = {
    columnNames: string[],
    contactFieldNames?: string[],
    contactHistoryFieldNames?: string[],
    customFieldNames: string[],
}

export class ContactEntityManager extends ContactGeneratedEntityManager<Contact>
{
    constructor()
    {
        super({
            createEntity: () => new Contact(),
        });
    }
}

export class Contact extends ContactGenerated
{
    static manager: ContactEntityManager = new ContactEntityManager();
    customFieldValueManager: CustomFieldValueManager = new CustomFieldValueManager(this, 'Contact');

    // override
    copy()
    {
        let copy = super.copy();

        // update the entity for the CustomFieldValueManager
        copy.customFieldValueManager.entity = copy;

        return copy;
    }

    /**
     * Subscribe a contact to one or more contact groups.
     * 
     * @param email The email address.
     * @param fieldValues The contact field values.
     * @param customFieldValues The custom field values.
     * @param contactGroupIds The contact group IDs.
     * @param markAsConfirmed Whether to mark the contact as confirmed.
     */
    static async subscribe(
        email: string, 
        fieldValues: { [key: string]: any }, 
        customFieldValues: { [key: string]: any }, 
        historyFieldValues?: { [key: string]: any },
        historyCustomFieldValues?: { [key: string]: any },
        contactGroupIds: number[] = [], 
        markAsConfirmed: boolean = false
    )
    {
        let query = `
            mutation Subscribe($email: String!, $fieldValues: JSON!, $customFieldValues: JSON!, $historyFieldValues: JSON, $historyCustomFieldValues: JSON, $contactGroupIds: [Int]!, $markAsConfirmed: Boolean) {
                subscribe(email: $email, fieldValues: $fieldValues, customFieldValues: $customFieldValues, historyFieldValues: $historyFieldValues, historyCustomFieldValues: $historyCustomFieldValues, contactGroupIds: $contactGroupIds, markAsConfirmed: $markAsConfirmed) {
                    success
                }
            }
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                email: email,
                fieldValues: JSON.stringify(fieldValues),
                customFieldValues: JSON.stringify(customFieldValues),
                historyFieldValues: historyFieldValues !== undefined ? JSON.stringify(historyFieldValues) : undefined,
                historyCustomFieldValues: historyCustomFieldValues !== undefined ? JSON.stringify(historyCustomFieldValues) : undefined,
                contactGroupIds: contactGroupIds,
                markAsConfirmed: markAsConfirmed,
            },
        });

        return result.data.subscribe.success as boolean;
    }

    /**
     * Prepare for contact import. Return the mapping for Excel column/field names.
     * 
     * @param file 
     * @returns The mapping.
     */
    static async getColumnNamesAndFieldNamesForMapping(file: File)
    {
        let query = `
            query GetColumnNamesAndFieldNamesForMapping($file: Upload!) {
                getColumnNamesAndFieldNamesForMapping(file: $file)
            }          
        `;

        let result = await client.query({ 
            query: gql(query),
            variables: {
                file: file,
            },
        });

        return JSON.parse(result.data.getColumnNamesAndFieldNamesForMapping) as ColumnNamesAndFieldNamesForMapping;
    }

    /**
     * Import contacts from Excel file.
     * 
     * @param file The Excel file.
     * @param columnNameToFieldNameMap The mapping for Excel column/field names. An example: { 'First Name': "firstName", 'Last Name': "lastName" }
     * @param markAsConfirmed Whether to mark the contacts as confirmed.
     * @param contactGroup The contact group.
     */
    static async importContactsFromExcel(file: File, columnNameToFieldNameMap: { [key: string]: string }, markAsConfirmed: boolean, contactGroup?: ContactGroup)
    {
        let query = `
            mutation ImportContactsFromExcel($file: Upload!, $columnNameToFieldNameMap: JSON!, $markAsConfirmed: Boolean, $contactGroupId: Int) {
                importContactsFromExcel(file: $file, columnNameToFieldNameMap: $columnNameToFieldNameMap, markAsConfirmed: $markAsConfirmed, contactGroupId: $contactGroupId) {
                    success
                }
            }
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                file: file,
                columnNameToFieldNameMap: JSON.stringify(columnNameToFieldNameMap),
                markAsConfirmed: markAsConfirmed,
                contactGroupId: contactGroup?.id,
            },
        });
        
        return result.data.importContactsFromExcel.success as boolean;
    }

    /**
     * Export contacts to Excel file.
     * 
     * @param segmentConditions The segment conditions.
     * @returns The download link.
     * 
     */
    static async exportContactsToExcel(segmentConditions: SegmentCondition[])
    {
        let mutation = `
        mutation ExportContactsToExcel($conditions : [SegmentConditionForExportType]) {
            exportContactsToExcel(conditions: $conditions) {
                success
                downloadLink
            }
        }
        `;
        
        let result = await client.mutate({
            mutation: gql(mutation),
            variables: {
                conditions: segmentConditions.map((segmentCondition) => ({
                    fieldName: segmentCondition.fieldName,
                    operator: segmentCondition.operator,
                    value: segmentCondition.value,
                })),
            },
        });

        return { success: result.data.exportContactsToExcel.success as boolean, downloadLink: result.data.exportContactsToExcel.downloadLink as string };
    }

    /**
     * Unsuscribe a contact from contact groups.
     * 
     * @param contactId The contact ID.
     * @param unsubscribeToken The unsubscribe token.
     * @param contactGroupIds The contact group IDs. If list is empty, unsubscribe from all contact groups.
     * @param emailMessageId The email message ID which the contact used to unsubscribe.
     * @param emailCampaignId The email campaign ID which the contact used to unsubscribe.
     * @param automatedJourneyMessageId The automated journey message ID which the contact used to unsubscribe.
     */
    static async unsubscribe(contactId: number, unsubscribeToken: string, contactGroupIds: number[], emailMessageId?: number, emailCampaignId?: number, automatedJourneyMessageId?: number)
    {
        let query = `
            mutation Unsubscribe($contactId: Int!, $unsubscribeToken: String!, $contactGroupIds: [Int], $emailMessageId: Int, $emailCampaignId: Int, $automatedJourneyMessageId: Int) {
                unsubscribe(contactId: $contactId, unsubscribeToken: $unsubscribeToken, contactGroupIds: $contactGroupIds, emailMessageId: $emailMessageId, emailCampaignId: $emailCampaignId, automatedJourneyMessageId: $automatedJourneyMessageId) {
                    success
                }
            }
        `;
        
        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                contactId: contactId,
                unsubscribeToken: unsubscribeToken,
                contactGroupIds: contactGroupIds,
                emailMessageId: emailMessageId,
                emailCampaignId: emailCampaignId,
                automatedJourneyMessageId: automatedJourneyMessageId,
            }
        });

        return result.data.unsubscribe.success;
    }
}

export default Contact;