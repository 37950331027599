import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { enumToDataSource, FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import Contact from '@business/contacts/Contact';
import Country from '@business/addresses/Country';
import ContactHistoryView from './ContactHistoryView';
import CustomField, { CustomFieldValue } from '@business/fields/CustomField';
import ContactGroup from '@business/contacts/ContactGroup';
import ContactGroupsEditor from './ContactGroupsEditor';
import CustomFieldsEditor from './CustomFieldsEditor';
import { CustomFieldAvailableFor } from '@business/fields/generated/CustomField.generated';
import { ContactGender } from '@business/contacts/generated/Contact.generated';

interface ContactAddEditFormProps extends EntityAddEditFormProps<Contact>
{
    // add the contact to these contact groups if in Add mode
    defaultContactGroups?: ContactGroup[];
}

const ContactAddEditForm: FunctionComponent<ContactAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<Contact>(props.entity ?? new Contact());
    const [countries, setCountries] = useState<Country[]>([]);
    const [customFieldValues, setCustomFieldValues] = useState<CustomFieldValue[]>([]);
    const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);

    /** Load additional data */
    useLoad(async () => {
        // load countries
        let countryResult = await Country.manager.load();
        if (countryResult != null) setCountries(countryResult.entities);

        // load custom fields and values
        let customFieldResult = await CustomField.manager.load({ availableFor_In: [CustomFieldAvailableFor.ALL, CustomFieldAvailableFor.CONTACT] });
        if (customFieldResult != null)
        {
            let cfvs: CustomFieldValue[] = await entity.customFieldValueManager.getValuesForCustomFields(customFieldResult.entities, mode == AddEditFormMode.Edit);
            setCustomFieldValues(cfvs);
        }
        
        if (mode == AddEditFormMode.Add && props.defaultContactGroups)
        {// set default contact groups
            setContactGroups(props.defaultContactGroups);
        }
        else if (mode == AddEditFormMode.Edit)
        {// load existing contact groups
            let contactGroupResult = await entity?.contactGroups.load();
            if (contactGroupResult) setContactGroups(contactGroupResult.entities);
        }
    });

    /** Save */
    const onSave = async () =>
    {
        // save entity
        let success = await entity.save();

        if (success)
        {
            // save custom field values
            for (let cfv of customFieldValues)
            {
                await entity.customFieldValueManager.setCustomFieldValue(cfv.customField, cfv.value);
            }

            // save contact groups
            await entity.contactGroups.set(contactGroups);
        }

        return success;
    }
    
    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Email}
                        field="email"
                        label={t('EMAIL')}
                        isRequired={true}
                        value={entity?.email}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="firstName"
                        label={t('FIRST_NAME')}
                        value={entity?.firstName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="lastName"
                        label={t('LAST_NAME')}
                        value={entity?.lastName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    /> 
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="phone"
                        label={t('PHONE')}
                        value={entity?.phone}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="isConfirmed"
                        label={t('IS_CONFIRMED')}
                        value={entity?.isConfirmed}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="gender"
                        label={t('GENDER')}
                        dataSource={enumToDataSource(ContactGender, t)}
                        value={entity?.gender}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: address */
    let cmpTabAddress =
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="address"
                        label={t('ADDRESS')}
                        value={entity?.address}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="city"
                        label={t('CITY')}
                        value={entity?.city}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="country"
                        label={t('COUNTRY')}
                        dataSource={countries}
                        dataSourceValueMember="code"
                        dataSourceDisplayMember="name"
                        value={entity?.country}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: custom fields */
    let cmpTabCustomFields =
        <CustomFieldsEditor 
            customFieldValues={customFieldValues}
            onCustomFieldValuesChanged={(customFieldValues) => setCustomFieldValues(customFieldValues)}
        />
    ;
    
    /** Tab: contact groups */
    let cmpTabContactGroups =
        <>
            <Row>
                <Col>
                    <ContactGroupsEditor
                        selectedContactGroups={contactGroups}
                        onChange={(contactGroups) => setContactGroups(contactGroups)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Tab: history */
    let cmpTabHistory =
        <>
            <ContactHistoryView 
                contact={entity}
            />
        </>

    /** Render */
    return (
        <>
            <EntityAddEditForm<Contact>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('CONTACT').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.email}
                onSave={() => onSave()}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {
                            eventKey: 'address',
                            title: t('ADD_EDIT_TAB_ADDRESS'),
                            content: cmpTabAddress,
                        },
                        {
                            eventKey: 'customFields',
                            title: t('ADD_EDIT_TAB_CUSTOM_FIELDS'),
                            content: cmpTabCustomFields,
                        },
                        {
                            eventKey: 'contactGroups',
                            title: t('ADD_EDIT_TAB_CONTACT_GROUPS'),
                            content: cmpTabContactGroups,
                        },
                        {
                            eventKey: 'history',
                            title: t('ADD_EDIT_TAB_CONTACT_HISTORY'),
                            content: cmpTabHistory,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default ContactAddEditForm;