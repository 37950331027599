import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import CustomField from '@business/fields/CustomField';
import { CustomFieldAvailableFor, CustomFieldType } from '@business/fields/generated/CustomField.generated';
import CustomFieldGroup from '@business/fields/CustomFieldGroup';

interface CustomFieldAddEditFormProps extends EntityAddEditFormProps<CustomField>
{

}

const CustomFieldAddEditForm: FunctionComponent<CustomFieldAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<CustomField>(props.entity ?? new CustomField());
    const [customFieldGroups, setCustomFieldGroups] = useState<CustomFieldGroup[]>([]);

    /** Load data */
    useLoad(async () => {
        // load custom field groups
        let customFieldGroupResult = await CustomFieldGroup.manager.load();
        if (customFieldGroupResult) setCustomFieldGroups(customFieldGroupResult.entities);
    });

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="type"
                        label={t('TYPE')}
                        isRequired={true}
                        dataSource={enumToDataSource(CustomFieldType, t)}
                        value={entity?.type}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Number}
                        field="orderNo"
                        label={t('ORDER_NO')}
                        isRequired={true}
                        value={entity?.orderNo}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="isRequired"
                        label={t('IS_REQUIRED')}
                        value={entity?.isRequired}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="customFieldGroup"
                        label={t('CUSTOM_FIELD_GROUP')}
                        dataSource={customFieldGroups}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="name"
                        value={entity?.customFieldGroup}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="availableFor"
                        label={t('AVAILABLE_FOR')}
                        dataSource={enumToDataSource(CustomFieldAvailableFor, t)}
                        value={entity?.availableFor}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<CustomField>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('CUSTOM_FIELD').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.name}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default CustomFieldAddEditForm;