import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomField, { CustomFieldValue } from '@business/fields/CustomField';
import CustomFieldValueEditor from './CustomFieldValueEditor';

interface CustomFieldsEditorProps {
    customFieldValues: CustomFieldValue[];
    onCustomFieldValuesChanged: (customFieldValues: CustomFieldValue[]) => void;
}

const CustomFieldsEditor: FunctionComponent<CustomFieldsEditorProps> = (props) => {
    // Group by CustomFieldGroup.name
    const groupedByCustomFieldGroup = props.customFieldValues.reduce<Record<string, CustomFieldValue[]>>( 
        (acc, curr) => {
            const groupName = curr.customField?.customFieldGroup?.name || 'No Group'; 
            if (!acc[groupName]) {
                acc[groupName] = [];
            }
            acc[groupName].push(curr);
            return acc;
        }, 
        {}
    );

    // Sort groups by CustomFieldGroup.orderNo
    const sortedGroupNames = Object.keys(groupedByCustomFieldGroup).sort(
        (a, b) => {
            const orderA = groupedByCustomFieldGroup[a][0]?.customField?.customFieldGroup?.orderNo || 0;
            const orderB = groupedByCustomFieldGroup[b][0]?.customField?.customFieldGroup?.orderNo || 0;
            return orderA - orderB;
        }
    );

    /** Render */
    return (
        <>
            {sortedGroupNames.map(groupName => {
                const customFieldValuesInGroup = groupedByCustomFieldGroup[groupName];

                return (
                    <div key={groupName} className="mb-3">
                        <div className="h4 mb-3 pb-1 border-bottom">{groupName}</div>
                        {Array.from({ length: Math.ceil(customFieldValuesInGroup.length / 2) }).map((_, idx) => {
                            const firstIndex = idx * 2;
                            const secondIndex = firstIndex + 1;

                            return (
                                <Row key={firstIndex}>
                                    {/* First Column */}
                                    <Col>
                                        <CustomFieldValueEditor
                                            customFieldValue={customFieldValuesInGroup[firstIndex]}
                                            onValueChanged={(value) => {
                                                let cfvs = [...props.customFieldValues];
                                                const actualIndex = props.customFieldValues.indexOf(customFieldValuesInGroup[firstIndex]);
                                                cfvs[actualIndex].value = value;
                                                props.onCustomFieldValuesChanged(cfvs);
                                            }}
                                        />
                                    </Col>

                                    {/* Second Column, only if it exists */}
                                    {secondIndex < customFieldValuesInGroup.length && (
                                        <Col>
                                            <CustomFieldValueEditor
                                                customFieldValue={customFieldValuesInGroup[secondIndex]}
                                                onValueChanged={(value) => {
                                                    let cfvs = [...props.customFieldValues];
                                                    const actualIndex = props.customFieldValues.indexOf(customFieldValuesInGroup[secondIndex]);
                                                    cfvs[actualIndex].value = value;
                                                    props.onCustomFieldValuesChanged(cfvs);
                                                }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
}

export default CustomFieldsEditor;