import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import Contact from '@business/contacts/Contact';


export class ContactHistoryGeneratedEntityManager<B extends ContactHistoryGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'ContactHistory',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'contact', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'contactId', inputProperty: 'contact.id', relatedManager: Contact.manager },
				{ name: 'eventName', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'eventDate', type: BusinessEntityFieldType.DateTime, isRequiredInput: true },
				{ name: 'customFieldValues', type: BusinessEntityFieldType.Json, isInput: false },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'contactHistories',
        });
    }

}
export class ContactHistoryGenerated extends BusinessEntity
{
	contact!: Contact;
	eventName!: string;
	eventDate!: string;
	customFieldValues: any | null = null;

}

export default ContactHistoryGenerated;