import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import BusinessEntity, { BusinessEntityFilter, EntityManager, PaginationInfo } from '@xFrame4/business/base/BusinessEntity';
import { AddEditFormMode } from './AddEditForm';
import { EntityViewPageInfo, EntityViewPagingDirection } from './EntityView';

/** 
 * A page reload cursor is the the endCursor for the previous GraphQL Relay page. 
 * The page can be reloaded with "after: endCursor"
 */
export type PageReloadCursor = {
    page: number, 
    cursor: string | undefined
}

/**
 *  Hook: load paginated entities.
 * 
 * @param entityManager The Business entity manager
 * @param initialFilter The initial filter that is applied on the first load. It's probably a filter that was stored int the Redux store.
 * @param baseFilter The filter that is always applied to manager.load() function.
 * @param pageSize How many entities to show on a page.
 * @param customQuery A custom GraphQL query for selecting the entity fields. If specified, nodes will be loaded with EntityManager.loadCustom()
 */
 export function useLoadPaginatedEntities<B extends BusinessEntity>(
    entityManager: EntityManager<B>,
    baseFilter: BusinessEntityFilter = {},
    initialFilter: BusinessEntityFilter = {},
    pageSize: number,
    customQuery?: string)
    : [boolean, B[], Dispatch<SetStateAction<B[]>>, PaginationInfo | undefined, PageReloadCursor[], EntityViewPageInfo, Dispatch<SetStateAction<EntityViewPageInfo>>]
{
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entities, setEntities] = useState<B[]>([]);

    /** Info aboout the current page of entities. */
    const [pageInfo, setPageInfo] = useState<EntityViewPageInfo>({
        page: 0,
        pagingDirection: EntityViewPagingDirection.Forward,
        businessEntityPaging: {
            first: pageSize
        },
        businessEntityFilter: initialFilter
    });

    /** Page reload cursors. */
    const [pageReloadCursors, setPageReloadCursors] = useState<PageReloadCursor[]>([]);

    /** GraphQL Relay PageInfo */
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();

    /** Loads the entities via GraphQL */
    const load = async () => 
    {
        console.log(`Page: ${pageInfo.page}`);

        // Calculate and set the reload cursor for the current page
        if (pageInfo.pagingDirection == EntityViewPagingDirection.Forward)
        {
            let pageReloadCursor = {
                page: pageInfo.page,
                cursor: pageInfo.page == 0 ? undefined : paginationInfo?.endCursor
            };
            console.log('Calculated page reload cursor', pageReloadCursor);

            if (!pageReloadCursors.some(rc => rc.page == pageInfo.page))
            {
                setPageReloadCursors([pageReloadCursor, ...pageReloadCursors]);
            }
            else
            {
                setPageReloadCursors(pageReloadCursors.map(rc =>
                {
                    return rc.page == pageInfo.page ? pageReloadCursor : rc;
                }));
            }
        }
        console.log('Page reload cursors:', pageReloadCursors);

        // Load entities
        setIsLoading(true);
        
        // Choose beetween load and loadCustom
        let result: any;
        if (customQuery === undefined)
        {
            result = await entityManager.load({ ...pageInfo.businessEntityFilter, ...baseFilter }, pageInfo.businessEntityPaging);
        }
        else
        {
            result = await entityManager.loadCustom(customQuery, { ...pageInfo.businessEntityFilter, ...baseFilter }, pageInfo.businessEntityPaging);
        }
        console.log('Entities for current page:', result);
        
        // Set the pagination info and the entities
        if (result != null)
        {
            setPaginationInfo(result.paginationInfo);
            customQuery === undefined ? setEntities(result.entities) : setEntities(result.entities);
        }

        setIsLoading(false);
    };

    /** Hook: load the entities if pageInfo changes. */
    useEffect(() =>
    {
        console.log('Reloading entities for view ...')
        console.log('Page info', pageInfo);
        console.log('Base filter', baseFilter);
        load();
    }, [pageInfo, baseFilter]);

    return [isLoading, entities, setEntities, paginationInfo, pageReloadCursors, pageInfo, setPageInfo];
}

/**
 * Hook: handle the added or editied entities. 
 * 
 * @param addedEntity The new entity that was added.
 * @param editedEntity The entity that was edited.
 * @param setEntities The entities state setter.
 * @param setPageInfo The page info state setter.
 */
export function useHandleAddEdit<B extends BusinessEntity>(addedEntity: B | undefined, editedEntity: B | undefined, setEntities: Dispatch<SetStateAction<B[]>>, setPageInfo: Dispatch<SetStateAction<EntityViewPageInfo>>)
{
    /** Add entity: go to the first page and reload the grid. */
    useEffect(() => {
        console.log('EntityView: addedEntity changed: ' + addedEntity?.id);
        if (addedEntity)
        {
            setPageInfo(prev => {
                let newPageInfo = { ...prev };
                newPageInfo.page = 0;
                newPageInfo.pagingDirection = EntityViewPagingDirection.Forward;
                return newPageInfo;
            });
        }
    }, [addedEntity]);

    /** Edit entity: find the entity and update. Does not reload the page. */
    useEffect(() => {
        console.log('EntityView: editedEntity changed: ' + editedEntity?.id);
        if (editedEntity)
        {
            setEntities(prev => {
                let newEntities = [...prev];
                let index = newEntities.findIndex(e => e.id === editedEntity?.id);
                if (index >= 0)
                {
                    newEntities[index] = editedEntity!;
                }
                return newEntities;
            });
        }
    }, [editedEntity]);
}

/**
 * Hook: Handle the AddEditForm events inside an EntityView.
 * 
 * @param entityClass The class of the entity (of type B)
 */
export function useOnAddEdit<B extends BusinessEntity>(entityClass: any): [B | undefined, B | undefined, B | undefined, (mode: AddEditFormMode, entity?: B) => void, (mode: AddEditFormMode, entity: B) => void]
{
    const [addEditEntity, setAddEditEntity] = useState<B>();
    const [addedEntity, setAddedEntity] = useState<B>();
    const [editedEntity, setEditedEntity] = useState<B>();

    const onAddEditOpen = (mode: AddEditFormMode, entity?: B) =>
    {
        if (entity == null) entity = new entityClass();

        setAddEditEntity(entity);
        setAddedEntity(undefined);
        setEditedEntity(undefined);
    }

    const onAddEditClose = (mode: AddEditFormMode, entity: B) =>
    {
        setAddEditEntity(undefined);

        if (mode == AddEditFormMode.Add && entity.id) setAddedEntity(entity);
        if (mode == AddEditFormMode.Edit) setEditedEntity(entity);
    }

    return [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose];
}