import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import EmailMessage from '@business/mailing/EmailMessage';
import SmtpSetup from '@business/mailing/SmtpSetup';
import User from '@xFrame4/business/users/User';
import ContactGroup from '@business/contacts/ContactGroup';
import Segment from '@business/contacts/Segment';

export enum EmailCampaignStatus
{
    DRAFT = 'DRAFT',
    SCHEDULED = 'SCHEDULED',
    SENDING = 'SENDING',
    SENT = 'SENT',
    CANCELLED = 'CANCELLED',

}

export class EmailCampaignGeneratedEntityManager<B extends EmailCampaignGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'EmailCampaign',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'status', type: BusinessEntityFieldType.VarChar },
				{ name: 'sendDate', type: BusinessEntityFieldType.DateTime },
				{ name: 'emailMessage', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'emailMessageId', inputProperty: 'emailMessage.id', relatedManager: EmailMessage.manager },
				{ name: 'smtpSetup', type: BusinessEntityFieldType.ForeignKey, inputName: 'smtpSetupId', inputProperty: 'smtpSetup.id', relatedManager: SmtpSetup.manager },
				{ name: 'user', type: BusinessEntityFieldType.ForeignKey, inputName: 'userId', inputProperty: 'user.id', relatedManager: User.manager },
				{ name: 'isSplitTest', type: BusinessEntityFieldType.Boolean },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'emailCampaigns',
        });
    }

}
export class EmailCampaignGenerated extends BusinessEntity
{
	name!: string;
	status: EmailCampaignStatus = EmailCampaignStatus.DRAFT;
	sendDate: string | null = null;
	emailMessage!: EmailMessage;
	smtpSetup: SmtpSetup | null = null;
	user!: User;
	isSplitTest: boolean = false;

	contactGroups: ManyToManyCollection<ContactGroup> = new ManyToManyCollection<ContactGroup>(this, 'business/mailing/EmailCampaign', 'contact_groups', 'business/contacts/ContactGroup', ContactGroup.manager, 'emailCampaign');
	segments: ManyToManyCollection<Segment> = new ManyToManyCollection<Segment>(this, 'business/mailing/EmailCampaign', 'segments', 'business/contacts/Segment', Segment.manager, 'emailCampaign');
	splitTestMessages: ManyToManyCollection<EmailMessage> = new ManyToManyCollection<EmailMessage>(this, 'business/mailing/EmailCampaign', 'split_test_messages', 'business/mailing/EmailMessage', EmailMessage.manager, 'splitTestEmailCampaign');
}

export default EmailCampaignGenerated;