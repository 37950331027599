import { FunctionComponent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import { useSetStateFromProp, useTranslate } from '@xFrame4/components/Hooks';
import SegmentCondition from '@business/contacts/SegmentCondition';
import SegmentConditionEditor from './SegmentConditionEditor';

interface SegmentConditionEditorContainerProps
{
    segmentConditions: SegmentCondition[];
    onChange: (segmentConditions: SegmentCondition[]) => void;
}

const SegmentConditionEditorContainer: FunctionComponent<SegmentConditionEditorContainerProps> = (props) =>
{
    const t = useTranslate();
    const [segmentConditions, setSegmentConditions] = useState<SegmentCondition[]>(props.segmentConditions);
    useSetStateFromProp(setSegmentConditions, props.segmentConditions);

    /**
     * Add a new segment condition
     */
    const add = () =>
    {
        let copy = [...segmentConditions, new SegmentCondition()];
        setSegmentConditions(copy);
        props.onChange(copy);
    }

    /**
     * Remove a segment condition
     * @param index The index of the segment condition to remove
     */
    const remove = (index: number) =>
    {
        let copy = segmentConditions.filter((item, i) => i !== index);
        setSegmentConditions(copy);
        props.onChange(copy);
    }

    /** Segment condition editors */
    let cmpSegmentConditions = segmentConditions.map((segmentCondition, i) =>
        <SegmentConditionEditor
            key={i}
            index={i}
            segmentCondition={segmentCondition}
            onAdd={() => add()}
            onChange={(changedSegmentCondition) => {
                let copy = segmentConditions.map((item, j) => 
                    j === i ? changedSegmentCondition : item
                );
                setSegmentConditions(copy);
                props.onChange(copy);
            }}
            onRemove={(index) => remove(index)}
        />
    );
    
    /** Render */
    return (
        <>
            <Button variant="outline-secondary" onClick={() => add()} className="mb-3">
                <AddIcon />
            </Button>
            {cmpSegmentConditions}
        </>
    );
}

export default SegmentConditionEditorContainer;