import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@xFrame4/components/admin/EntityView';
import { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import OptInForm from '@business/forms/OptInForm';
import OptInFormAddEditForm from './OptInFormAddEditForm';

interface OptInFormViewProps
{

}

const OptInFormView: FunctionComponent<OptInFormViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<OptInForm>(OptInForm);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<OptInForm>>(
        () => [
            { field: 'id', headerName: 'ID', type: 'number', width: 100 },
            { field: 'name', headerName: t('NAME'), type: 'string', flex: 1 },
            { field: 'isPopup', headerName: t('IS_POPUP'), type: 'boolean', width: 150 },
        ],
        [],
    );

    /** Render */
    return (
        <>
            {addEditEntity &&
            <OptInFormAddEditForm 
                entity={addEditEntity}
                afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
            />
            }

            <EntityView<OptInForm>
                id="optInForms"
                isVisible={addEditEntity === undefined}
                title={t('OPT_IN_FORMS')}
                manager={OptInForm.manager}
                baseFilter={{}}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    {field: 'name_Icontains', label: t('NAME'), type: FieldEditorType.Text}
                ]}
                actions={[]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.name + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default OptInFormView;