import OptInFormGenerated, { OptInFormGeneratedEntityManager } from './generated/OptInForm.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class OptInFormEntityManager extends OptInFormGeneratedEntityManager<OptInForm>
{
    constructor()
    {
        super({
            createEntity: () => new OptInForm(),
        });
    }
}

export class OptInForm extends OptInFormGenerated
{
    static _manager: OptInFormEntityManager;
    static get manager(): OptInFormEntityManager
    {
        if (!this._manager) this._manager = new OptInFormEntityManager();
        return this._manager;
    }
}

export default OptInForm;