import { FunctionComponent, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FieldEditor, { FieldEditorType, stringArrayToDataSource, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import { useLoad, useSetStateFromProp, useTranslate } from '@xFrame4/components/Hooks';
import SegmentCondition from '@business/contacts/SegmentCondition';
import { SegmentConditionOperator } from '@business/contacts/generated/SegmentCondition.generated';
import CustomField from '@business/fields/CustomField';

interface SegmentConditionEditorProps
{
    segmentCondition: SegmentCondition;
    index: number;
    onAdd: () => void;
    onChange: (segmentCondition: SegmentCondition) => void;
    onRemove: (index: number) => void;
}

const SegmentConditionEditor: FunctionComponent<SegmentConditionEditorProps> = (props) =>
{
    const t = useTranslate();
    const [segmentCondition, setSegmentCondition] = useState<SegmentCondition>(props.segmentCondition);
    useSetStateFromProp(setSegmentCondition, props.segmentCondition);
    const [fieldNames, setFieldNames] = useState<{value: string, display: string}[]>([]);

    useLoad(async () => {
        // load possible field names
        let fieldNamesRaw = await SegmentCondition.getFieldNames();
        
        let customFieldNames: string[] = [];
        let customFieldResult = await CustomField.manager.load();
        if (customFieldResult)
        {
            customFieldNames = customFieldResult.entities.map((customField) => customField.name);
        }

        let fieldNames = fieldNamesRaw.map((fieldName) => {
            let display = '';
            if (customFieldNames.find((customFieldName) => fieldName.indexOf(customFieldName) > -1) === undefined)
            {// translate the field name to a human readable format
                display = t('SEGMENT_FIELD_NAME_' + fieldName);
            }
            else
            {// remove the filter prefix from the custom field names
                display = fieldName.replace('history__custom_field_values__JSON__', '');
                display = display.replace('custom_field_values__JSON__', '');
            }
            
            return {
                value: fieldName,
                display: display
            }
        });  
        setFieldNames(fieldNames);
    });
    
    /** Render */
    return (
        <>
            <Row>
                <Col xs={10}>
                    <Row>
                        <Col xs={4}>
                            <FieldEditor 
                                type={FieldEditorType.Select}
                                field="fieldName"
                                label={t('FIELD_NAME')}
                                isRequired={true}
                                dataSource={fieldNames}
                                value={segmentCondition.fieldName}
                                onValueChanged={(field, value) => {
                                    let copy = segmentCondition.copy();
                                    copy.fieldName = value.value;
                                    setSegmentCondition(copy as SegmentCondition);
                                    props.onChange(copy as SegmentCondition);
                                }}
                            />
                        </Col>
                        <Col xs={4}>
                            <FieldEditor
                                type={FieldEditorType.Select}
                                field="operator"
                                label={t('OPERATOR')}
                                isRequired={true}
                                dataSource={enumToDataSource(SegmentConditionOperator, t)}
                                value={segmentCondition.operator}
                                onValueChanged={(field, value) => {
                                    let copy = segmentCondition.copy();
                                    copy.operator = value.value;
                                    setSegmentCondition(copy as SegmentCondition);
                                    props.onChange(copy as SegmentCondition);
                                }}
                            />
                        </Col>
                        <Col xs={4}>
                            <FieldEditor 
                                type={FieldEditorType.Text}
                                field="value"
                                label={t('VALUE')}
                                isRequired={true}
                                value={segmentCondition.value}
                                onValueChanged={(field, value) => {
                                    let copy = segmentCondition.copy();
                                    copy.value = value;
                                    setSegmentCondition(copy as SegmentCondition);
                                    props.onChange(copy as SegmentCondition);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={2}>
                    <div className="d-flex h-100 align-items-end">
                        <Button variant="outline-secondary" onClick={() => props.onAdd()} className="me-2" style={{marginBottom: '2rem'}}>
                            <AddIcon />
                        </Button>
                        <Button variant="outline-secondary" onClick={() => props.onRemove(props.index)} style={{marginBottom: '2rem'}}>
                            <RemoveIcon />
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default SegmentConditionEditor;