import { gql } from '@apollo/client';
import client from '@graphql/apollo-client';
import { AuthUser } from '@xFrame4/business/users/AuthUser';
import AutomatedJourney from './AutomatedJourney';
import EmailTemplate from './EmailTemplate';
import AutomatedJourneyMessageGenerated, { AutomatedJourneyMessageDelayUnit, AutomatedJourneyMessageGeneratedEntityManager } from './generated/AutomatedJourneyMessage.generated';
import { BulkMessageStats } from './BulkMessageStatsManager';

export class AutomatedJourneyMessageEntityManager extends AutomatedJourneyMessageGeneratedEntityManager<AutomatedJourneyMessage>
{
    constructor()
    {
        super({
            createEntity: () => new AutomatedJourneyMessage(),
        });
    }
}

export class AutomatedJourneyMessage extends AutomatedJourneyMessageGenerated
{
    static _manager: AutomatedJourneyMessageEntityManager;
    static get manager(): AutomatedJourneyMessageEntityManager
    {
        if (!this._manager) this._manager = new AutomatedJourneyMessageEntityManager();
        return this._manager;
    }

    async getStats()
    {
        let query = `
            query GetAutomatedJourneyMessageStats($automatedJourneyMessageId: Int!) {
                getAutomatedJourneyMessageStats(automatedJourneyMessageId: $automatedJourneyMessageId) 
            }
        `;

        let result = await client.query({
            query: gql(query),
            variables: {
                automatedJourneyMessageId: this.id
            }
        });

        if (result.data.getAutomatedJourneyMessageStats)
        {
            return JSON.parse(result.data.getAutomatedJourneyMessageStats) as BulkMessageStats;
        }
        else
        {
            return null;
        }
    }

    static async createDraft(automatedJourney: AutomatedJourney, emailTemplate: EmailTemplate, delay: number, delayUnit: AutomatedJourneyMessageDelayUnit, bodySoFar: string = '')
    {
        let query = `
            mutation CreateDraftAutomatedJourneyMessage($automatedJourneyId: Int!, $emailTemplateId: Int!, $delay: Int!, $delayUnit: String!, $bodySoFar: String!) {
                createDraftAutomatedJourneyMessage(automatedJourneyId: $automatedJourneyId, emailTemplateId: $emailTemplateId, delay: $delay, delayUnit: $delayUnit, bodySoFar: $bodySoFar) {
                    success
                    object {
                        ...${AutomatedJourneyMessage.manager.name}DetailsFragment
                    }
                }
            }
            ${AutomatedJourneyMessage.manager.buildEntityDetailsFragment()}
        `;

        let result = await client.mutate({
            mutation: gql(query),
            variables: {
                automatedJourneyId: automatedJourney.id,
                emailTemplateId: emailTemplate.id,
                delay: delay,
                delayUnit: delayUnit,
                bodySoFar: bodySoFar
            }
        });

        if (result.data.createDraftAutomatedJourneyMessage.success)
        {
            return AutomatedJourneyMessage.manager.createFromGraphQL(result.data.createDraftAutomatedJourneyMessage.object);
        }
        else
        {
            return null;
        }
    }
}

export default AutomatedJourneyMessage;