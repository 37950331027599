import { FunctionComponent, useState } from 'react';
import EmailCampaign from '@business/mailing/EmailCampaign';
import AutomatedJourneyMessage from '@business/mailing/AutomatedJourneyMessage';
import EmailMessage from '@business/mailing/EmailMessage';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import { PaginatedResult } from '@xFrame4/business/base/BusinessEntity';
import EmailMessageEditor from './EmailMessageEditor';
import { Button } from 'react-bootstrap';
import EntityAddEditForm from '@xFrame4/components/admin/EntityAddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import AddEditForm from '@xFrame4/components/admin/AddEditForm';

interface SplitTestMessagesFormProps
{
    emailCampain?: EmailCampaign;
    automatedJourneyMessage?: AutomatedJourneyMessage;
    onClose: () => void;
}

const SplitTestMessagesForm: FunctionComponent<SplitTestMessagesFormProps> = (props) =>
{
    const t = useTranslate();
    const [splitTestMessages, setSplitTestMessages] = useState<EmailMessage[]>([]);
    const [activeTab, setActiveTab] = useState<string | undefined>();

    /** Load data */
    useLoad(async () =>
    {
        // load the split test messages
        let emailMessageResult: PaginatedResult<EmailMessage> | null = null;
        if (props.emailCampain != null) emailMessageResult = await props.emailCampain.splitTestMessages.load();
        if (props.automatedJourneyMessage != null) emailMessageResult = await props.automatedJourneyMessage.splitTestMessages.load();
        if (emailMessageResult != null && emailMessageResult.entities.length > 0)
        {
            setSplitTestMessages(emailMessageResult.entities);
        }
        else
        {
            setSplitTestMessages([new EmailMessage()]);
        }
    });

    /** 
     * Save the split test messages 
     */
    const save = async () =>
    {
        // save the split test messages
        let success = false;
        for (let splitTestMessage of splitTestMessages)
        {
            success = await splitTestMessage.save();
            if (!success) break;
        }
        
        // add the split test messages to the m2m collections
        if (success)
        {
            if (props.emailCampain != null) 
            {
                success = await props.emailCampain.splitTestMessages.set(splitTestMessages);
            }
            if (props.automatedJourneyMessage != null) 
            {
                success = await props.automatedJourneyMessage.splitTestMessages.set(splitTestMessages);
            }
        }

        return success;
    }

    /** 
     * Delete a message 
     * 
     * @param index The index of the message to delete
     */
    const deleteMessage = (index: number) => 
    {
        const updatedMessages = [...splitTestMessages];
        updatedMessages.splice(index, 1);
        setSplitTestMessages(updatedMessages);
    }

    /** Render */
    return (
        <>
            <AddEditForm
                title={t('SPLIT_TEST_MESSAGES')}
                onSave={() => save()}
                onClose={() => props.onClose()}
            >
                <>
                    <div className="d-flex mb-3">
                        <Button
                            variant="success"
                            onClick={() => {
                                let newEmailMessage = new EmailMessage();
                                setSplitTestMessages([...splitTestMessages, newEmailMessage]);
                                setActiveTab(newEmailMessage.uniqueId); // Set the active tab to the last one after adding a new item
                            }}
                            className="me-3"
                        >
                            <i className="mdi mdi-plus" />
                        </Button>
                    </div>
                    {splitTestMessages && splitTestMessages.length > 0 &&
                    <TabContainer
                        activeKey={activeTab}
                        onTabChange={(key) => {
                            setActiveTab(key);  // Update the active tab state when a tab is changed
                            return true;
                        }}
                        tabs={
                            splitTestMessages.map((emailMessage, index) => 
                            {
                                return {
                                    eventKey: emailMessage.uniqueId.toString(),
                                    title: t('SPLIT_TEST_MESSAGE') + ' ' + (index + 1),
                                    content: <>
                                        <div>
                                            <EmailMessageEditor
                                                emailMessage={emailMessage}
                                                onEmailMessageChanged={(emailMessage) =>
                                                {
                                                    let updatedMessages = [...splitTestMessages];
                                                    updatedMessages[index] = emailMessage;
                                                    setSplitTestMessages(updatedMessages);
                                                }}
                                            />
                                        </div>
                                        <Button
                                            variant="danger"
                                            onClick={() => deleteMessage(index)}
                                        >
                                            <i className="mdi mdi-delete" />
                                        </Button>
                                    </>,
                                }
                            })
                    }
                    />
                    }
                </>
            </AddEditForm>
        </>
    );
}

export default SplitTestMessagesForm;