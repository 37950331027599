import React from 'react';
import BusinessEntity from '@xFrame4/business/base/BusinessEntity';
import AddEditForm, { AddEditFormMode, AddEditFormProps } from './AddEditForm';

export interface EntityAddEditFormProps<B extends BusinessEntity> extends AddEditFormProps
{
    entity?: B;
    /** Called after the form has been closed. */
    afterEdit?: (mode: AddEditFormMode, entity: B) => void;
}

function EntityAddEditForm<B extends BusinessEntity>(props: EntityAddEditFormProps<B>)
{
    /** Default handler for saving the entity. */
    const onSave = async () =>
    {
        return props.entity!.save();
    };
    
    /** Render */
    return (
        <>
        {props.entity &&
            <AddEditForm 
                mode={props.mode}
                title={props.title}
                titleAdd={props.titleAdd}
                titleEdit={props.titleEdit}
                saveButtonLabel={props.saveButtonLabel}
                cancelButtonLabel={props.cancelButtonLabel}
                modalSize={props.modalSize}
                modalPosition={props.modalPosition}
                errorMessage={props.errorMessage}
                onValidate={props.onValidate}
                onSave={props.onSave ?? onSave}
                afterSave={props.afterSave}
                onClose={props.onClose}
            >
                {props.children}
            </AddEditForm>
        }
        </>
    );
}

export default EntityAddEditForm;