import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import ContactGroup from '@business/contacts/ContactGroup';

interface ContactGroupAddEditFormProps extends EntityAddEditFormProps<ContactGroup>
{

}

const ContactGroupAddEditForm: FunctionComponent<ContactGroupAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<ContactGroup>(props.entity ?? new ContactGroup());

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        isRequired={true}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="description"
                        label={t('DESCRIPTION')}
                        value={entity?.description}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<ContactGroup>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('CONTACT_GROUP').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.name}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default ContactGroupAddEditForm;